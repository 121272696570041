/* eslint-disable max-len */
import React from "react";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { Container } from "../../../../components/core/03-base/Container";
import { StepsHeader } from "../../../../components/core/09-views/00-blocks/StepsHeader";
import { MReportGroupItem } from "../../../../store/Init/Types";
import { GroupComp } from "../00-blocks/Reports/Group";

interface Props {
    url?: string;
    title: string;
    goBackLink?: string;
    currentPath?: string;
    results?: MReportGroupItem[];
}


/**
 *
 */
export class ReportGroup
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);
    }

    /**
     *
     */
    public render() {
        return (
            <Page>
                <>
                    <StepsHeader title={this.props.title} hasSmallTitle goBack={this.props.goBackLink} />
                    <Container theme={{
                        margin: {
                            "": { t: 3 },
                            "sm": { t: 4 },
                        },
                    }}>
                        <GroupComp results={this.props.results} currentPath={this.props.currentPath} />
                    </Container>
                </>
            </Page>
        );
    }
}

export default ReportGroup;
