import * as React from "react";
import DatePicker, { ReactDatePickerProps, registerLocale } from "react-datepicker";
import moment from "moment";
import nl from "date-fns/locale/nl";
import { BaseConsumer } from "../03-base/Base";
registerLocale("nl", nl);

export interface DateInputProps extends Pick<ReactDatePickerProps, "popperPlacement"> {
    className?: string;
    value?: string | number;
    onChange?: (value?: string) => void;
    minDate?: Date;
    maxDate?: Date;
    disabled?: boolean;
    required?: boolean;
}

type Props = DateInputProps;

const onChangeDate = (date?: Date | null, onChange?: (value?: string) => void) => {
    if (!!onChange) {
        onChange(
            date ? moment(date).format("YYYY-MM-DD") : undefined);
    }
};


/**
 *
 * @param props
 */
export const DateInput: React.FunctionComponent<Props> = (props) => (
    <BaseConsumer>{({ getLanguage }) =>
        (
            <DatePicker
                selected={!!props.value && typeof props.value === "string" ? new Date(props.value) : undefined}
                dateFormat={getLanguage() === "nl" ? "dd/MM/yyyy": "dd-MM-yyyy"}
                onChange={(value: Date | null) => onChangeDate(value, props.onChange)}
                className={props.className}
                isClearable={!!props.disabled ? false : true}
                placeholderText={getLanguage() === "nl" ? "DD/MM/JJJJ" : "DD-MM-YYYY"}
                popperPlacement={props.popperPlacement || "top"}
                minDate={props.minDate}
                maxDate={props.maxDate}
                disabled={!!props.disabled}
                required={props.required}
                locale={getLanguage() === "nl" ? getLanguage() : undefined}
            />)
    }
    </BaseConsumer>
);

export const testUtil = {
    onChangeDate: onChangeDate,
};
