import * as React from "react";
import { asThemeConsumer } from "../../../../theme/Theme";
import { ThemeConsumerProps } from "../../../../theme/_Types";
import { TextArea } from "../../05-atoms/TextArea";

import { ValidationFeedback, fieldValidationIcon, fieldValidationMessage } from "./Field.utils";
import { FormGroup } from "./Group";

export interface FieldTextAreaValueProps {
    value?: string;
    type?: string;
}

export interface FieldTextAreaValidationProps {
    validationFunction?: (value: string | number) => ValidationFeedback;
}

interface FieldProps {
    label: string;
    placeholder?: string;
    disabled?: boolean;
    onChange?: (value?: string | number) => void;
    required?: boolean;
    max?: number;
    asSummary?: boolean;
    raw?: boolean;
}

/**
 *
 */
type Props = ThemeConsumerProps &
FieldProps &
FieldTextAreaValidationProps &
FieldTextAreaValueProps;

/**
 *
 * @param props
 */
const FormTextAreaComp: React.FunctionComponent<Props> = (props) => (
    <FormGroup {...props}>
        <TextArea
            style={props.style}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            required={props.required}
            max={props.max}
            asSummary={props.asSummary}
            raw={props.raw}
        />
        {fieldValidationMessage(props) &&
                (
                    <div className="scl-o-form__info">
                        <div className="scl-o-form__info-text">
                            {fieldValidationMessage(props)}
                        </div>
                        <div className="scl-o-form__info-icon">
                            {fieldValidationIcon(props)}
                        </div>
                    </div>
                )
        }
    </FormGroup>
);

/**
 *
 */
export const FormTextArea = asThemeConsumer(FormTextAreaComp);
