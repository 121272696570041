/* eslint-disable @typescript-eslint/no-unsafe-return */

import { getText } from "../utils/translations/I18n";
import { ReduxState } from "./ReduxState";
import { RemoteScope } from "./RemoteTypes";


export const getSelectedRoleFromStateOrLocalStorage = (s: ReduxState) => (
    s.prop("selectedRole") ? s.prop("selectedRole") : localStorage.getItem?.("selectedRole") ?? undefined
);

export const getSelectedRoleId = (s: ReduxState): string | undefined => {
    const roles = s.prop("remote")?.prop(RemoteScope.INIT)?.roles;
    const userId = s.prop("remote")?.prop(RemoteScope.INIT)?.email;
    if (userId && roles && roles?.length > 0) {
        if ((roles?.length === 1)
            && s.prop("remote").prop(RemoteScope.INIT)?.roles[0]) {
            return s.prop("remote").prop(RemoteScope.INIT)?.roles[0].id;
        } else {
            const currentRole = roles
                .filter(role => role.id === getSelectedRoleFromStateOrLocalStorage(s));
            return currentRole?.[0] ? currentRole?.[0].id : undefined;
        }
    } else {
        return undefined;
    }
};

export const getCurrentRoles = (s: ReduxState) => (
    s.prop("remote").prop(RemoteScope.INIT)?.roles
        .filter(role => (role.id === s.prop("userContext")?.id))
);

export const getCurrentRole = (s: ReduxState) => (
    s.prop("remote").prop(RemoteScope.INIT)?.roles
        .filter(role => (role.id === s.prop("userContext")?.id))?.[0]
);

export const getMenuForCurrentRole = (s: ReduxState) => (getCurrentRoles(s)?.map(role => role.menu)[0] ?? []);

export type UserInfo = Readonly<{
    token?: string;
}>;

export interface GlobalLoadingProps {
    isLoading: {
        customers: boolean;
        init: boolean;
        customer: boolean;
        role: boolean;
    };
    isLoaded: {
        init: boolean;
    };
}

export const isLoadingGlobal = (l: Omit<GlobalLoadingProps, "isLoaded">) =>
    Object.values(l.isLoading).some(i => i === true);
export const loadingMessagesGlobal = (l: Omit<GlobalLoadingProps, "isLoaded">) => Object.keys(l.isLoading)
    .filter(i => l.isLoading[i] === true )
    .map(m => `${getText(`app.retrieving-${m}..`) ?? ""}\n`);


