import * as React from "react";

import { Menu, MenuItem, MenuOrientation } from "../../../../components/core/07-organisms/Menu";
import { OrderedSet } from "immutable";
import { PageDisplay, Pages } from "../../../../store/AppDisplays";
import { MReports } from "../../../../store/Init/Types";
import { ReduxState } from "../../../../store/ReduxState";
import { connect } from "react-redux";
import { showDisclaimer } from "../02-pages/Disclaimer";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { getMenuForCurrentRole } from "../../../../store/AppTypes";
import { getText } from "../../../../utils/translations/I18n";

interface StateProps {
    reportItems?: MReports;
    userId?: string;
}

/**
 *
 */
interface OwnProps {
    tabs: OrderedSet<Pages>;
}

/**
 *
 */
interface DispatchProps {
    toggleDisclaimer: (userId: string) => void;
}


/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    reportItems: (getMenuForCurrentRole(s)?.find(m => m.key === Pages.REPORTS) as MReports) ?? undefined,
    userId: s.prop("remote").prop(RemoteScope.INIT)?.email,
});

/**
 *
 */
const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    toggleDisclaimer: (userId: string) => showDisclaimer(dispatch, userId, true),
});


/**
 *
 */
export const DashboardMenuComp = (props: Props) => (
    <Menu
        orientation={MenuOrientation.VERTICAL}
        items={getItems(props)}
        // collapsable
    />
);

/**
 *
 * @param props
 */
export const getItems = (props: Props): MenuItem[] => {
    const tabs =  props.tabs
        .map((page: Pages): MenuItem => {
            let item: MenuItem = {
                children: getText(PageDisplay[page].title),
                navLink: PageDisplay[page].path,
                beta: PageDisplay[page].beta,
                link: {
                    onClick: () => {
                        if (PageDisplay[page].path === location.pathname) {
                            console.log("User wants to reload this path, Claude will reload the location.");
                            window.location.reload();
                        }
                    },
                },
            };
            if (page === Pages.REPORTS) {
                item = {
                    children: getText(PageDisplay[page].title),
                    navLink: PageDisplay[page].path,
                    beta: PageDisplay[page].beta,
                    items: props.reportItems?.items?.map(i => ({
                        // eslint-disable-next-line max-len
                        children: <>{i.name}{i.items && <span className="scl-h-text--tiny">&nbsp;({i.items?.length})</span>}</>,
                        navLink: `${PageDisplay[Pages.REPORTS].path}/${i.name.toLowerCase()}`,
                        beta: false,
                    })),
                };
            }
            return item;
        })
        .toArray();
    if (!!props.userId){
        const userId = props.userId;
        tabs.push({
            children: getText("app.disclaimer", "Disclaimer"),
            link: {
                onClick: () => props.toggleDisclaimer(userId),
            },
        });
    }

    return tabs;
};
/**
 *
 */
export const DashboardMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DashboardMenuComp);
