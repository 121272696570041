import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { Customer } from "../../store/Customers/Types";
import { useAxios } from "../utils/AxiosContext";
import { APIErrorResponses } from "./errors";

// Used to set the customer on the backend side
export const useSetCustomer = (callBack: (customer?: Customer) => void, id?: string) => {
    const axios = useAxios();
    const user =
    useQuery<AxiosResponse<Customer>, APIErrorResponses>(["customers/post", id],
        async () => !!id ? await axios.post(`/customers/${id}`, {
            responseType: "json",
        }).then((m) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            callBack((m.data?.[0] as Customer) ?? undefined);
            return m;
        }) : await axios.delete("/customers", {
            responseType: "json",
        }).then((m) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            callBack();
            return m;
        }),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            retry: false,
            enabled: true,
        });
    return user;
};

