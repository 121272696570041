/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from "react";
import { ErrorView } from "../components/core/09-views/02-pages/Error";
import {Buffer} from "buffer";


/**
 *
 */
interface State {
    hasError: boolean;
    errorInfo?: string; // also has client info from navigator object
}

/**
 *
 */
export class ErrorBoundary
    extends React.Component<{}, State> {

    /**
     *
     * @param props
     */
    public constructor(props: {}) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    /**
     *
     * @param _error
     */
    public static getDerivedStateFromError(_error: {}) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    /**
     *
     * @param _error
     * @param info
     */
    public componentDidCatch(_error: Error, info: React.ErrorInfo) {
        // You can also log the error to an error reporting service

        const errorInfo = `
        Error Information:
        ${info.componentStack}
        `;
        const clientInfo = `
        ClientInformation:
        UserAgent: ${navigator.userAgent}
        cookieEnabled: ${navigator.cookieEnabled.toString()}
        `;
        const date = `Date: ${new Date()}`;
        const allInfo = `
            ${errorInfo}
            ${clientInfo}
            ${date}
        `;
        const buffData = Buffer.from(allInfo);

        console.warn(allInfo);

        this.setState({
            hasError: true,
            errorInfo: buffData.toString("base64"),
        });
    }

    /**
     *
     */
    public render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <ErrorView info={this.state.errorInfo}/>
            );
        }

        return this.props.children;
    }
}
