/* eslint-disable max-len */
import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { AvailableRoles, ReducerFn } from "../ReduxState";

/**
 *
 */
type UpdateSelectedRoleAction = Readonly<
Action<ActionTypeKeys.UPDATE_CURRENT_ROLE> & {
    selectedRole: AvailableRoles | string;
}
>;

/**
 *
 * @param selectedRole
 */
export const updateSelectedRole:
(selectedRole: AvailableRoles | string) => UpdateSelectedRoleAction =
    (selectedRole) => ({
        type: ActionTypeKeys.UPDATE_CURRENT_ROLE,
        selectedRole: selectedRole,
    });

/**
 *
 */
export const updateSelectedRoleReducer:
ReducerFn<UpdateSelectedRoleAction> =
    (s, a) => {
        localStorage.setItem("selectedRole", a.selectedRole);
        return (
            s.setProp("selectedRole", a.selectedRole)
        );
    };

export type ClearSelectedRoleAction = Readonly<
Action<ActionTypeKeys.CLEAR_CURRENT_ROLE>
>;

/**
 *
 *
 */
export const clearSelectedRole:
() => ClearSelectedRoleAction =
    () => ({
        type: ActionTypeKeys.CLEAR_CURRENT_ROLE,
    });

/**
 *
 */
export const clearSelectedRoleReducer:
ReducerFn<ClearSelectedRoleAction> =
    (s, _a) => {
        localStorage.setItem("selectedRole", "");
        return (
            s.setProp("selectedRole", undefined)
        );
    };
