import * as React from "react";
import { ThemeConsumerProps } from "../../../theme/_Types";
import { joinClasses } from "../../../utils/Filters";
import { ensure } from "../../../utils/Optional";
import { asThemeConsumer } from "../../../theme/Theme";
import { RTE } from "../07-organisms/RTE";

/**
 *
 */
export interface TextAreaProps
    extends ThemeConsumerProps {
    placeholder?: string;
    value?: string;
    onChange?: (value?: string) => void;
    disabled?: boolean;
    required?: boolean;
    max?: number;
    asSummary?: boolean;
    raw?: boolean;
}

/**
 *
 * @param props
 */
const TextAreaComp: React.FunctionComponent<TextAreaProps> = (props) => {
    const clazzName = joinClasses(
        props.className,
        "scl-a-textarea__field",
        props.asSummary ? "scl-a-textarea__field--as-summary-textarea" : "",
    );

    const onChange = !!props.onChange
        ? (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
            evt.preventDefault();
            ensure(props.onChange)(evt.target.value || undefined);
        }
        : undefined;

    return (
        props.asSummary ? (
            <div className={clazzName} style={{height: "auto"}}>
                <RTE content={props.value || ""} raw={props.raw} />
            </div> )
            : (
                <textarea
                    style={props.style}
                    className={clazzName}
                    placeholder={props.placeholder}
                    readOnly={!onChange}
                    value={props.value || ""}
                    onChange={onChange}
                    disabled={props.disabled}
                    required={props.required}
                    maxLength={props.max}
                />
            )
    );
};

/**
 *
 */
export const TextArea = asThemeConsumer(TextAreaComp);
