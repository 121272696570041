export const formatLicensePlate = (value?: string) => {
    const rawValue = parseLicenseplate(value);
    if (rawValue && rawValue.length === 6) {
        const sidecode = getSidecode(value);
        if (sidecode <= 6) {
            return `${rawValue.substr(0, 2)}-${rawValue.substr(2, 2)}-${rawValue.substr(4, 2)}`.toUpperCase();
        }
        if (sidecode === 7 || sidecode === 9) {
            return `${rawValue.substr(0, 2)}-${rawValue.substr(2, 3)}-${rawValue.substr(5, 1)}`.toUpperCase();
        }
        if (sidecode === 8 || sidecode === 10) {
            return `${rawValue.substr(0, 1)}-${rawValue.substr(1, 3)}-${rawValue.substr(4, 2)}`.toUpperCase();
        }
        if (sidecode === 11 || sidecode === 14) {
            return `${rawValue.substr(0, 3)}-${rawValue.substr(3, 2)}-${rawValue.substr(5, 1)}`.toUpperCase();
        }
        if (sidecode === 12 || sidecode === 13) {
            return `${rawValue.substr(0, 1)}-${rawValue.substr(1, 2)}-${rawValue.substr(3, 3)}`.toUpperCase();
        }
    }
    return value?.toUpperCase();
};

export const isValidLicensePlate = (value?: string): boolean => {
    const rawValue = parseLicenseplate(value);
    if (rawValue && rawValue.length === 6) {
        const sidecode = getSidecode(rawValue);
        if (sidecode) {
            return sidecode !== -1;
        }
    }
    return false;
};

const getSidecode = (value?: string) => {
    const licenseplate = parseLicenseplate(value);
    const sidecodesArr = sidecodes();
    const index = sidecodesArr.findIndex(item => licenseplate?.match(item));
    return index + 1;
};

export const parseLicenseplate = (value?: string) => value?.replace(/-/g, "").toUpperCase();

const sidecodes = () => {
    // See https://nl.wikipedia.org/wiki/Nederlands_kenteken#Alle_sidecodes
    const sidecodes = [];
    sidecodes[0] = /^[a-zA-Z]{2}[\d]{2}[\d]{2}$/; // 1 XX-99-99
    sidecodes[1] = /^[\d]{2}[\d]{2}[a-zA-Z]{2}$/; // 2 99-99-XX
    sidecodes[2] = /^[\d]{2}[a-zA-Z]{2}[\d]{2}$/; // 3 99-XX-99
    sidecodes[3] = /^[a-zA-Z]{2}[\d]{2}[a-zA-Z]{2}$/; // 4 XX-99-XX
    sidecodes[4] = /^[a-zA-Z]{2}[a-zA-Z]{2}[\d]{2}$/; // 5 XX-XX-99
    sidecodes[5] = /^[\d]{2}[a-zA-Z]{2}[a-zA-Z]{2}$/; // 6 99-XX-XX
    sidecodes[6] = /^[\d]{2}[a-zA-Z]{3}[\d]{1}$/; // 7 99-XXX-9
    sidecodes[7] = /^[\d]{1}[a-zA-Z]{3}[\d]{2}$/; // 8 9-XXX-99
    sidecodes[8] = /^[a-zA-Z]{2}[\d]{3}[a-zA-Z]{1}$/; // 9 XX-999-X
    sidecodes[9] = /^[a-zA-Z]{1}[\d]{3}[a-zA-Z]{2}$/; // 10 X-999-XX
    sidecodes[10] = /^[a-zA-Z]{3}[\d]{2}[a-zA-Z]{1}$/; // 11 XXX-99-X
    sidecodes[11] = /^[a-zA-Z]{1}[\d]{2}[a-zA-Z]{3}$/; // 12 X-99-XXX
    sidecodes[12] = /^[\d]{1}[a-zA-Z]{2}[\d]{3}$/; // 13 9-XX-999
    sidecodes[13] = /^[\d]{3}[a-zA-Z]{2}[\d]{1}$/; // 14 999-XX-9
    return sidecodes;
};

export const testUtil = {
    parseLicenseplate: parseLicenseplate,
};
