export const DateToDateTimeString = (date?: Date): string => {
    if (!date) {
        return "";
    }
    date = new Date(date);
    return `${DateToDateString(date)} ${DateToTimeString(date)}`;
};

export const DateToDateString = (date?: Date): string => {
    if (!date) {
        return "";
    }
    date = new Date(date);
    return [date.getFullYear(), date.getMonth() + 1, date.getDate()]
        .map(x => x.toString())
        .map(x => x.length === 1 ? "0" + x : x)
        .join("-");
};

export const DateToTimeString = (date?: Date): string => {
    if (!date) {
        return "";
    }
    date = new Date(date);
    return [date.getHours(), date.getMinutes()]
        .map(x => x.toString())
        .map(x => x.length === 1 ? "0" + x : x)
        .join(":");
};

export const valueAsNum = (value?: number | string): number => (
    typeof value === "number" ? value :
        !!value ? parseFloat(value) : 0
);

export const isNegative = (amount: number): boolean => (amount < 0);

export const BytesToFileSizeString = (bytes: number): string => {
    const unitLabels = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let unit = bytes;

    let i = 0;
    while (unit >= 1024) {
        unit = unit / 1024;
        i++;
    }

    unit = unit * 10;
    unit = Math.floor(unit);
    unit = unit / 10;

    return `${unit} + ${unitLabels[i]}`;
};

export const formatedDateOfBirth = (DateOfBirth: string): string => (
    DateOfBirth.toString().split("-").reverse().join("-")
);

export const formattedDate = (date: Date): string => (date.toISOString().slice(0, 10));

export const formatedCurrentDate = (d: Date): string => {
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    const year = d.getFullYear();

    if (month.length < 2) {
        month = "0" + month;
    }
    if (day.length < 2) {
        day = "0" + day;
    }
    return [day, month, year].join("-");
};

export const absoluteValue = (value: number): number => (
    Math.abs(value)
);

export const monthsToYears = (month: string): string => {
    const numberOfYears = Number(month) / 12;
    return `${numberOfYears} years`;
};

export const licensePlate = (license: string): string => {
    let plate = license.split(/([0-9]+)/).join("-");
    if (plate.charAt(0) === "-") {
        plate = plate.slice(1);
    }
    if (plate.charAt(plate.length - 1) === "-") {
        plate = plate.slice(0, plate.length - 1);
    }
    return plate;
};
