import * as React from "react";
import { ThemeConsumerProps } from "../../../../theme/_Types";
import { joinClasses } from "../../../../utils/Filters";
import { Div } from "../../03-base/Div";
import { Label } from "../../05-atoms/Label";

import { FieldCheckValidationProps, fieldValidationState } from "./Field.utils";

interface OwnProps {
    label?: string;
    required?: boolean;
    asSummary?: boolean;
}

/**
 *
 */
type Props = ThemeConsumerProps & OwnProps & FieldCheckValidationProps;

/**
 *
 * @param props
 */
export const FormGroup: React.FunctionComponent<Props> = (props) => {
    const clazzName = joinClasses(
        props.className || "",
        "scl-o-form__group",
        props.asSummary ? "scl-o-form__group--as-summary" : "",
    );

    return (
        <Div className={clazzName} transparent theme={{ paletteState: fieldValidationState(props) }}>
            {props.label &&
                <Label className={props.asSummary ? "scl-a-label__field--as-summary" : ""}>
                    {props.label}
                    {props.required ? <span className={"scl-a-label__field--as-required"}>*</span> : ""}
                </Label>
            }
            {props.children}
        </Div>
    );
};
