import { Action } from "redux";
import { State } from "../../utils/redux/State";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
// import { RemoteScope } from "../RemoteTypes";
import { Role, RoleRequest } from "./Types";

/**
 *
 */
export type InitUpdateRoleAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_ROLE> & {
    role?: Role;
}
>;

/**
 *
 *
 */
export const initUpdateRole =
     (role?: Role): InitUpdateRoleAction => ({
         type: ActionTypeKeys.INIT_UPDATE_ROLE,
         role: role,
     });

/**
 *
 * @param s
 * @param a
 */
export const initUpdateRoleReducer:
ReducerFn<InitUpdateRoleAction> =
     (s, a) => {
         const role = a.role ? a.role : {};
         localStorage.setItem("roleRequest", JSON.stringify(role));
         return (
             s.setProp(
                 "roleRequest",
                 s.prop("roleRequest").update(() => role),
             )
         );
     };

/**
 *
 */
export type UpdateRoleAction = Readonly<
Action<ActionTypeKeys.UPDATE_ROLE> & {
    role?: Partial<RoleRequest>;
}
>;

/**
 *
 *
 */
export const updateRole =
    (role: RoleRequest): UpdateRoleAction => ({
        type: ActionTypeKeys.UPDATE_ROLE,
        role: role,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateRoleReducer:
ReducerFn<UpdateRoleAction> =
    (s, a) => {
        const role = s.setProp(
            "roleRequest",
            s.prop("roleRequest").updateWith(
                a.role ?? {},
            ),
        );
        localStorage.setItem("roleRequest", JSON.stringify(role.map(m => m).roleRequest.map(f => f)));
        return (role);
    };

/**
 *
 */
export type ClearRoleAction = Readonly<
Action<ActionTypeKeys.CLEAR_ROLE>
>;

/**
 *
 * @param keys
 */
export const clearRole:
() => ClearRoleAction =
    () => ({
        type: ActionTypeKeys.CLEAR_ROLE,
    });

/**
 *
 */
export const clearRoleReducer:
ReducerFn<ClearRoleAction> =
    (s, _a) => (
        s.setProp(
            "roleRequest",
            State.create<RoleRequest>(() => ({})),
        )
    );

export type UpdateRoleReportAction = Readonly<
Action<ActionTypeKeys.UPDATE_ROLE_REPORT> & {
    reports?: string[];
}
>;

/**
 *
 *
 */
export const updateRoleReport =
    (reports?: string[]): UpdateRoleReportAction => ({
        type: ActionTypeKeys.UPDATE_ROLE_REPORT,
        reports: reports,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateRoleReportReducer:
ReducerFn<UpdateRoleReportAction> =
    (s, a) => (
        s.setProp(
            "roleRequest",
            s.prop("roleRequest").setProp("dashboards", a.reports ?? []),
        )
    );

export type ClearRoleReportAction = Readonly<
Action<ActionTypeKeys.CLEAR_ROLE_REPORT>>;

/**
 *
 *
 */
export const clearRoleReport =
    (): ClearRoleReportAction => ({
        type: ActionTypeKeys.CLEAR_ROLE_REPORT,
    });

/**
 *
 * @param s
 * @param a
 */
export const clearRoleReportReducer:
ReducerFn<ClearRoleReportAction> =
    (s, _a) => (
        s.setProp(
            "roleRequest",
            s.prop("roleRequest").setProp("dashboards", []),
        )
    );
