import { Action } from "redux";
import { State } from "../../utils/redux/State";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { UserRequest } from "./Types";

/**
 *
 */
export type InitUpdateUserAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_USER> & {
    user: UserRequest;
}
>;

/**
 *
 *
 */
export const initUpdateUser =
    (user: UserRequest): InitUpdateUserAction => ({
        type: ActionTypeKeys.INIT_UPDATE_USER,
        user: user,
    });

/**
 *
 * @param s
 * @param a
 */
export const initUpdateUserReducer:
ReducerFn<InitUpdateUserAction> =
    (s, a) => {
        const userDetails = a.user ? a.user : {};
        localStorage.setItem("userRequest", JSON.stringify(userDetails));
        return (
            s.setProp(
                "userRequest",
                s.prop("userRequest").update(() => userDetails),
            )
        );
    };

/**
 *
 */
export type UpdateUserAction = Readonly<
Action<ActionTypeKeys.UPDATE_USER> & {
    user?: UserRequest;
}
>;

/**
 *
 *
 */
export const updateUser =
    (user: Partial<UserRequest>): UpdateUserAction => ({
        type: ActionTypeKeys.UPDATE_USER,
        user: user,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateUserReducer:
ReducerFn<UpdateUserAction> =
    (s, a) => (
        s.setProp(
            "userRequest",
            s.prop("userRequest").updateWith(
                a.user ?? {},
            ),
        )
    );

/**
 *
 */
export type ClearUserAction = Readonly<
Action<ActionTypeKeys.CLEAR_USER>
>;

/**
 *
 * @param keys
 */
export const clearUser:
() => ClearUserAction =
    () => ({
        type: ActionTypeKeys.CLEAR_USER,
    });

/**
 *
 */
export const clearUserReducer:
ReducerFn<ClearUserAction> =
    (s, _a) => (
        s.setProp(
            "userRequest",
            State.create<Partial<UserRequest>>(() => ({})),
        )
    );
