/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
    Action,
    Store,
    applyMiddleware,
    compose,
    createStore,
} from "redux";
import thunk from "redux-thunk";

import { MaryActionTypeKeys } from "../utils/redux/ActionTypes";
import { withMaryReducers } from "../utils/redux/MaryReducer";
import { State } from "../utils/redux/State";
import { Reducer, Reducers } from "../utils/redux/StateReducers";
import { ActionTypeKeys, Config } from "./ActionTypes";
import {
    appInitReducer,
    toggleSidebarReducer,
    updatePagesReducer,
    updateThemeReducer,
    signoutReducer} from "./AppActions";
import { clearFiltersReducer, updateFiltersReducer } from "./FilterActions";
import { clearUserContextReducer,
    updateUserContextIsLoadingReducer,
    updateUserContextReducer } from "./Init/UpdateUserContext";
import { clearSelectedRoleReducer, updateSelectedRoleReducer } from "./Init/UpdateSelectedRole";
import { ReduxState, ReduxStateInit } from "./ReduxState";
import {
    remoteCancelReducer,
    remoteClearErrorReducer,
    remoteClearResponseReducer,
    remoteErrorReducer,
    remoteResponseReducer,
    remoteTriggerReducer,
} from "./RemoteActions";
import {
    clearRoleReducer,
    initUpdateRoleReducer,
    // initUpdateRoleReducer,
    updateRoleReducer,
    updateRoleReportReducer,
} from "./Roles/UpdateTypes";
import { clearUserReducer, initUpdateUserReducer, updateUserReducer } from "./Users/UpdateTypes";
import { clearSelectedCustomerReducer, updateSelectedCustomerReducer } from "./Init/UpdateSelectedCustomer";

// DO NOT TOUCH - WE KNOW WHAT WE ARE DOING, I SWEAR
// https://github.com/palantir/tslint/issues/144#issuecomment-195453810
//
// TODO: the disabled lint may or may not be needed here.

declare let window: any;
const composeEnhancers: any = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Reducer defines the type signature of this reducer.
 */
// type StoreConfig = Readonly<{
//     apiEndpoint: string;
// }>;

const rootReducer =
    withMaryReducers(
        Reducers
            .create<ActionTypeKeys | MaryActionTypeKeys, ReduxState>()
            .register(
                ActionTypeKeys.APP_INIT,
                appInitReducer as Reducer<ReduxState, Action<ActionTypeKeys.APP_INIT>>,
            )
            .register(
                ActionTypeKeys.SIGN_OUT,
                signoutReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_THEME,
                updateThemeReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_PAGES,
                updatePagesReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_FILTERS,
                updateFiltersReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_FILTERS,
                clearFiltersReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_TRIGGER,
                remoteTriggerReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_CANCEL,
                remoteCancelReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_RESPONSE,
                remoteResponseReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_CLEAR_RESPONSE,
                remoteClearResponseReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_ERROR,
                remoteErrorReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_CLEAR_ERROR,
                remoteClearErrorReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_CURRENT_ROLE,
                updateSelectedRoleReducer as Reducer<ReduxState, Action<ActionTypeKeys.UPDATE_CURRENT_ROLE>>
            )
            .register(
                ActionTypeKeys.CLEAR_CURRENT_ROLE,
                clearSelectedRoleReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_USER_CONTEXT,
                updateUserContextReducer as Reducer<ReduxState, Action<ActionTypeKeys.UPDATE_USER_CONTEXT>>
            )
            .register(
                ActionTypeKeys.CLEAR_USER_CONTEXT,
                clearUserContextReducer,
            )
            // Loading context (for when setting the session role or customer)
            .register(
                ActionTypeKeys.SET_USER_CONTEXT_IS_LOADING,
                updateUserContextIsLoadingReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_USER,
                initUpdateUserReducer as Reducer<ReduxState, Action<ActionTypeKeys.INIT_UPDATE_USER>>,
            )
            .register(
                ActionTypeKeys.UPDATE_USER,
                updateUserReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_USER,
                clearUserReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_ROLE,
                initUpdateRoleReducer as Reducer<ReduxState, Action<ActionTypeKeys.INIT_UPDATE_ROLE>>,
            )
            .register(
                ActionTypeKeys.UPDATE_ROLE,
                updateRoleReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_ROLE,
                clearRoleReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_ROLE_REPORT,
                updateRoleReportReducer,
            )
            .register(
                ActionTypeKeys.TOGGLE_SIDEBAR,
                toggleSidebarReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_CURRENT_CUSTOMER,
                updateSelectedCustomerReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_CURRENT_CUSTOMER,
                clearSelectedCustomerReducer,
            )
    );

export const createReduxStore: (conf: Config) => Store<ReduxState> =
    conf => createStore<ReduxState, Action<ActionTypeKeys | MaryActionTypeKeys>, {}, {}>(
        (state, action) => rootReducer.reduce(state || State.create(ReduxStateInit(conf)), action),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        composeEnhancers(applyMiddleware(thunk)),
    );

