import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { ThemePalette } from "../../../../theme/_Types";
import { Container } from "../../../../components/core/03-base/Container";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { LoadingIndications, LoadingIndicator } from "../../../../components/core/05-atoms/LoadingIndicator";
import { ErrorMessageComp } from "../../../components/09-views/00-blocks/ErrorMessage";
import { getText } from "../../../../utils/translations/I18n";
import { useSetCustomer } from "../../../api/customers";
import { Customer } from "../../../../store/Customers/Types";

interface Props {
    id?: string;
    init: boolean;
    updateCurrentCustomer: (customer?: Customer) => void;
    updateUserContextLoading: (loading: boolean) => void;
}

export const Component: React.FC<Props> = (props) => {
    const [isLoading, setLoading] = useState(false);
    const queryClient = useQueryClient();
    if (props.init) {
        queryClient.removeQueries(["customers/post", props.id]);
    }

    const response = useSetCustomer(props.updateCurrentCustomer, encodeURI(props.id ?? "").replace("#", ""));
    const hasError = !!response.error;

    useEffect(() => {
        const responseLoading = response.isLoading
        || response.isFetching;
        setLoading(responseLoading);
        props.updateUserContextLoading(responseLoading);
    });


    const getError = () => (<ErrorMessageComp errorMessage={response?.error?.response?.data?.code} />);

    const getLoader = () => (
        <>
            <LoadingIndicator type={LoadingIndications.DEFAULT}
                theme={{ palette: ThemePalette.BRAND_PRIMARY }}>
                {getText("app.retrieving-customer..", "Loading customer..")}
            </LoadingIndicator>
        </>
    );

    if (isLoading) {
        return getLoader();
    }

    if (hasError) {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { t: 3, b: 6 },
                            "sm": { t: 4, b: 6 },
                        },
                    }}
                >
                    {getError()}
                </Container>
            </Page>
        );
    }

    return (<>{props.children}</>);
};
