/* eslint-disable max-len */
import React from "react";
import { connect } from "react-redux";
import { LoadingIndications, LoadingIndicator } from "../../../components/core/05-atoms/LoadingIndicator";
import { DispatchFunc } from "../../../store/ActionTypes";
import { updatePages, updateTheme } from "../../../store/AppActions";
import { GlobalLoadingProps, loadingMessagesGlobal, getSelectedRoleId } from "../../../store/AppTypes";
import { updateSelectedRole } from "../../../store/Init/UpdateSelectedRole";
import { clearUserContext, updateUserContext, updateUserContextIsLoading } from "../../../store/Init/UpdateUserContext";
import { isRemoteLoading, ReduxState } from "../../../store/ReduxState";
import { RemoteScope } from "../../../store/RemoteTypes";
import { UserContext } from "../../../store/Users/Types";
import { ThemePalette, Themes } from "../../../theme/_Types";
import { FleetinfoThemes } from "../../theme/_Types";
import { WithoutRole } from "./RoleContext/WithoutRole";
import { WithRole } from "./RoleContext/WithRole";


interface OwnProps {
    setTheme: (to: Themes) => void;
}

export interface StateProps extends GlobalLoadingProps {
    roleID?: string;
    theme: Themes;
}

/**
 *
 */
interface DispatchProps {
    updateCurrentRole: (role: UserContext) => void;
    clearCurrentRole: () => void;
    updateCurrentRoleLoading: (loading: boolean) => void;
}

/**
 *
 */
type Props = React.PropsWithChildren<OwnProps & StateProps & DispatchProps>;

/**
 *
 */
export interface State {
}


export const mapStateToProps = (s: ReduxState): StateProps => ({
    isLoading: {
        customers: isRemoteLoading(s, RemoteScope.CUSTOMERS),
        customer: !!s.prop("userContextCustomerIsLoading"),
        role: !!s.prop("userContextRoleIsLoading"),
        init: isRemoteLoading(s, RemoteScope.INIT),
    },
    roleID: getSelectedRoleId(s),
    isLoaded: {
        init: !!s.prop("remote").prop(RemoteScope.INIT),
    },
    theme: s.prop("currentTheme").map(m => m).theme,
});


export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    updateCurrentRoleLoading: (loading) =>  dispatch(updateUserContextIsLoading(loading, "userContextRoleIsLoading")),
    updateCurrentRole: (role) => {
        dispatch(updateSelectedRole(role.role));
        dispatch(updateUserContext(role));
        dispatch(updatePages(role));
        dispatch(updateTheme(role));
    },
    clearCurrentRole: () => {
        // we do need to clear the session, but we shouldn't remove the selected role
        // because we check it anyway, so it doesn't matter if it's a wrong role.
        // dispatch(clearSelectedRole());
        dispatch(clearUserContext());
        dispatch(updatePages());
        dispatch(updateTheme());
    },
});

/**
 *
 */
export class RoleContextWrapperComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
    }

    public componentDidMount() {
        this.setCustomTheme(this.props.theme);
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.theme !== this.props.theme) {
            this.setCustomTheme(this.props.theme);
        }
    }

    /**
     *
     */
    public render() {
        if (!this.props.isLoading.customers && !this.props.isLoading.init && this.props.isLoaded.init) {
            if (this.props.roleID) {
                return (
                    <WithRole
                        roleId={this.props.roleID}
                        updateUserContextLoading={this.props.updateCurrentRoleLoading}
                        updateCurrentRole={this.props.updateCurrentRole}
                        clearCurrentRole={this.props.clearCurrentRole}
                    >
                        {this.props.children}
                    </WithRole>
                );
            } else {
                return (
                    <WithoutRole
                        updateUserContextLoading={this.props.updateCurrentRoleLoading}
                        clearCurrentRole={this.props.clearCurrentRole}
                    >
                        {this.props.children}
                    </WithoutRole>
                );
            }
        } else {
            return (
                <LoadingIndicator type={LoadingIndications.DEFAULT}
                    theme={{ palette: ThemePalette.BRAND_PRIMARY }}>
                    {loadingMessagesGlobal({isLoading: this.props.isLoading})}</LoadingIndicator>
            );
        }
    }

    private setCustomTheme = (theme: string | Themes) => {
        if (Object.keys(FleetinfoThemes).includes(theme)) {
            if (theme === FleetinfoThemes.DutchLease) {
                this.props.setTheme(Themes.DUTCHLEASE);
            } else if (theme === FleetinfoThemes["MAN FS"]) {
                this.props.setTheme(Themes.MAN);
            } else {
                this.props.setTheme(Themes.VWPFS);
            }
        } else if (Object.keys(Themes).includes(theme?.toUpperCase())) {
            this.props.setTheme(theme as Themes);
        } else {
            this.props.setTheme(Themes.VWPFS);
        }
    };
}

export const RoleContextWrapper = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RoleContextWrapperComp);


