import * as React from "react";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { InfoCard } from "../../../../../components/core/06-molecules/InfoCard";
import { MReportItem } from "../../../../../store/Init/Types";

interface Props {
    results?: MReportItem[];
}

export const ResultsComp: React.FunctionComponent<Props> = (props) => (
    <React.Fragment>
        <Div className="scl-b-row">
            {
                props.results?.map((report, index) => (
                    <Grid key={index} size={{ xs: 12, md: 6, lg: 3 }}>
                        <InfoCard
                            title={report.name}
                            navLink={"/reports/" + report.name.toLowerCase()}
                        />
                    </Grid>
                ))
            }
        </Div>
    </React.Fragment>
);
