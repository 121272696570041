/* eslint-disable */
import { Action } from "redux";
import { ActionTypeKeys, Thunk } from "./ActionTypes";
import { FilterScope, FilterTypes } from "./FilterTypes";
import { ReducerFn } from "./ReduxState";
import { UserFilters } from "./Users/Types";

/**
 *
 */
type UpdateFiltersAction = Readonly<
    Action<ActionTypeKeys.UPDATE_FILTERS> & {
        filters?: Partial<FilterTypes>;
        scope?: FilterScope;
    }
>;

/**
 *
 * @param filters
 */
export const updateFilters:
    (
        filters: Partial<FilterTypes>,
        scope: FilterScope) => Thunk<void> =
    (filters, scope) => ((dispatch, _getState) => {
        const action: UpdateFiltersAction = {
            type: ActionTypeKeys.UPDATE_FILTERS,
            filters: filters,
            scope: scope,
        };
        dispatch(action);
    });

export const filteredFilter = (oldFilters: FilterTypes, newFilters: FilterTypes): FilterTypes => {
    const clearFilterKeys = Object.keys(newFilters).filter(key => (
        newFilters[key] === ""
    ));
    const updateFilterKeys = Object.keys(newFilters).filter(key => (
        newFilters[key] !== ""
    ));
    const filteredFilters: FilterTypes = {};
    Object.keys(oldFilters).filter(f => (
        !clearFilterKeys.includes(f)
    )).forEach(key => (
        filteredFilters[key] = oldFilters[key]
    ));
    updateFilterKeys.forEach(key => (
        filteredFilters[key] = newFilters[key]
    ));
    return filteredFilters;
};

/**
 *
 */
export const updateFiltersReducer:
    ReducerFn<UpdateFiltersAction> =
    (s, a) => (
        a.scope ?
            a.scope === FilterScope.USER ?
                s.setProp(
                    "filters",
                    s.prop("filters").setProp(
                        a.scope,
                        s.prop("filters").prop(FilterScope.USER)
                            .update((oldFilters) => (
                                filteredFilter(oldFilters, a.filters ?? {}) as UserFilters
                            )),
                    )) : s : s)

/**
 *
 */
type ClearFiltersAction = Readonly<
    Action<ActionTypeKeys.CLEAR_FILTERS> &
    {
        keys?: Array<keyof UserFilters>;
        scope?: FilterScope;
    }
>;

/**
 *
 * @param keys
 */
export const clearFilters:
    (
        keys?: Array<keyof UserFilters>,
        scope?: FilterScope) => ClearFiltersAction =
    (keys, scope) => ({
        type: ActionTypeKeys.CLEAR_FILTERS,
        keys: keys,
        scope: scope,
    });

/**
 *
 */
export const clearFiltersReducer:
    ReducerFn<ClearFiltersAction> =
    (s, a) => (
        a.scope
            ?
            s.setProp(
                "filters",
                s.prop("filters").setProp(
                    a.scope,
                    s.prop("filters").prop(a.scope).clear(),
                ),
            )
            :
            s
    );
