/* eslint-disable max-len */
import React from "react";
import { Div } from "../../../../components/core/03-base/Div";
import { Blockquote } from "../../../../components/core/05-atoms/Blockquote";
import { Icon } from "../../../../components/core/05-atoms/Icon";
import { RTE } from "../../../../components/core/07-organisms/RTE";
import { ThemePaletteState } from "../../../../theme/_Types";
import { getText } from "../../../../utils/translations/I18n";
import { ErrorDisplay, ErrorResponses } from "../../../api/errors";


interface Props {
    message?: string;
    errorMessage?: ErrorResponses;
}

export const ErrorMessageComp: React.FC<Props> = (props) => {
    const message = props.errorMessage ? ErrorDisplay?.[props.errorMessage] : props.message;
    return (
        <Blockquote theme={{paletteState: ThemePaletteState.DANGER}} background>
            <RTE>
                <b>
                    <Icon name={"fas fa-exclamation-triangle"} />
                    &nbsp;&nbsp;{getText("app.claude-thinks-something-went-wrong", "Claude thinks something went wrong!")}
                </b>
                {(message) && (
                    <Div style={{paddingTop: "5px"}}>
                        {message}
                    </Div>
                )}
            </RTE>
        </Blockquote>
    );
};
