/* eslint-disable max-len */
import { RemoteErrorType, RemoteScope } from "../../store/RemoteTypes";
import { RemoteErrors } from "../../store/ReduxState";
import { Map } from "immutable";

export enum RemoteErrorsCodes {
    ERR_MISSING_CUSTOMER_ID = "ERR_MISSING_CUSTOMER_ID",
    ERR_CUSTOMER_NOT_FOUND = "ERR_CUSTOMER_NOT_FOUND",
    ERR_MISSING_KPI_ID = "ERR_MISSING_KPI_ID",
    ERR_DELETING_KPI = "ERR_DELETING_KPI",
    ERR_VALIDATION_FAILED = "ERR_VALIDATION_FAILED",
}

/**
 *
 */
export const ErrorTypeDisplay: Readonly<Record<RemoteErrorsCodes, string>> = {
    [RemoteErrorsCodes.ERR_MISSING_CUSTOMER_ID]: "The customer id is missing.",
    [RemoteErrorsCodes.ERR_CUSTOMER_NOT_FOUND]: "The customer is not found.",
    [RemoteErrorsCodes.ERR_MISSING_KPI_ID]: "The Kpi id is missing.",
    [RemoteErrorsCodes.ERR_DELETING_KPI]: "The KPI could not be deleted.",
    [RemoteErrorsCodes.ERR_VALIDATION_FAILED]: "There are validation errors.",
};

export const getErrorValidationMessage =
    (error: Map<RemoteScope, RemoteErrors>, rc: RemoteScope): RemoteErrors | undefined => {
        const errorEntry = error.findEntry((val) => !!val && val.type === RemoteErrorType.VALIDATION);
        let errMessage;
        if (errorEntry) {
            const [scope, reason] = errorEntry;
            errMessage = scope === rc ? reason : undefined;
        }
        return errMessage;
    };

export const hasErrorValidation =
    (error: Map<RemoteScope, RemoteErrors>, rc: RemoteScope): boolean => {
        const errorEntry = error.findEntry((val) => !!val && val.type === RemoteErrorType.VALIDATION);
        let hasError = false;
        if (errorEntry) {
            const [scope] = errorEntry;
            hasError = scope === rc;
        }
        return hasError;
    };

export interface ErrorData {
    code: RemoteErrorsCodes;
    errors?: [ { message: string } ];
}

export const getRawValidationMessages = (data: ErrorData) => {
    let messages = "";
    if (data.errors && data.errors?.length > 0) {
        data.errors.forEach(entry => {
            messages += `${entry.message}\n`;
        });
    } else {
        messages = ErrorTypeDisplay[data.code];
    }
    return messages;
};
