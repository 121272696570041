/* eslint-disable */
import * as React from "react";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { updateSelectedCustomer } from "../../../../../store/Init/UpdateSelectedCustomer";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { Customers } from "../../../../../store/Customers/Types";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { getText } from "../../../../../utils/translations/I18n";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { SelectOption } from "../../../../../components/core/05-atoms/Select";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import * as FormFields from "../../../../../components/core/07-organisms/Form/index";

/**
 *
 */
interface OwnProps {
}

interface State {
    options: SelectOption[];
    defaultOptions: SelectOption[];
}

/**
 *
 */
interface StateProps {
    selectedRole?: string;
    currentCustomer?: string;
    currentCustomerName?: string;
    customers?: Customers;
    filteredCustomers?: Customers;
    hasCustomers: () => boolean | undefined;
}

/**
 *
 */
interface DispatchProps {
    updateSelectedCustomer: (customer?: { name?: string; id?: string }) => void;
    getFilteredCustomers: (name?: string, callback?: (options: SelectOption[]) => void) => void;
    clearFilteredCustomers: () => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    selectedRole: s.prop("selectedRole"),
    currentCustomer: s.prop("selectedCustomer"),
    currentCustomerName: s.prop("selectedCustomerName"),
    customers: s.prop("remote").prop(RemoteScope.CUSTOMERS),
    filteredCustomers: s.prop("remote").prop(RemoteScope.FILTERED_CUSTOMERS),
    hasCustomers: () => {
        const customers = s.prop("remote")?.prop(RemoteScope.CUSTOMERS);
        return customers && customers?.length > 0;
    },
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    updateSelectedCustomer: (customer?: { name?: string; id?: string }) => {
        dispatch(updateSelectedCustomer(customer));
    },
    getFilteredCustomers: (name?: string, callback?: (options: SelectOption[]) => void) => {
        const mappedCallback =  (customers?: Customers) => {
            const options = customers?.map((c) => ({
                label: c.name,
                value: c.id,
            })) ?? []
            callback?.(options);
        };
        dispatch(remoteTrigger(RemoteScope.FILTERED_CUSTOMERS, {
            name,
            callback: mappedCallback,
        }));
    },
    clearFilteredCustomers: () => {
        dispatch(remoteClearResponse(RemoteScope.FILTERED_CUSTOMERS));
        dispatch(remoteClearError(RemoteScope.FILTERED_CUSTOMERS));
    }
});


/**
 *
 */
export class SetCustomerComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props) {
        super(props);

        this.state = {
            options: props.customers?.map(c => ({
                label: c.name,
                value: c.id,
            })) ?? [],
            defaultOptions: props.customers?.map(c => ({
                label: c.name,
                value: c.id,
            })) ?? [],
        };

        this.onChangeFilter = this.onChangeFilter.bind(this);
    }

    public componentDidMount() {
        if(!!this.props.currentCustomer) {
            this.props.getFilteredCustomers(this.props.currentCustomerName);
        }
    }

    public componentDidUpdate(prevProps: Props) {
        const defaultOptions = this.props.customers?.map(customer => ({
            label: customer.name,
            value: customer.id,
        })) || [];
        if ((prevProps.filteredCustomers !== this.props.filteredCustomers) && this.props.filteredCustomers) {
            const updatedOptions = this.props.filteredCustomers?.map(customer => ({
                label: customer.name,
                value: customer.id,
            })) || [];
            this.setState({ options: updatedOptions, defaultOptions });
        } else {
            if(!this.props.filteredCustomers || this.props.filteredCustomers.length === 0) {
                if ((defaultOptions.toString() !== this.state.options.toString()) && defaultOptions.length > 0) {
                    this.setState({ options: defaultOptions, defaultOptions });
                }
            }
        }
    }

    /**
     *
     */
    public render() {
        return this.props.selectedRole && this.props.hasCustomers() ? (
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12 }}
                >
                    {this.props.currentCustomer ? <FormFields.FormField
                        onChange={(value?: string | number) => {
                            const selectedCustomer = this.props.customers?.find(customer => customer.id === value);
                            this.props.updateSelectedCustomer({ name: selectedCustomer?.name, id: value?.toString()});
                            if(value === "") {
                                console.log("value is empty")
                                this.props.clearFilteredCustomers();
                            }
                        }}
                        onChangeFilter={this.onChangeFilter}
                        value={this.props.currentCustomer}
                        label={getText("app.select-a-customer")}
                        placeholder="Zoek en maak een keuze"
                        options={this.state.defaultOptions}
                        withFilters
                        validationFunction={(value: string | number) =>
                            hasValueValidation(value.toString(),
                                getText("app.customer-selected") ?? "")}
                    /> :
                        <FormFields.FormField
                            onChange={(value?: string | number) => {
                                const selectedCustomer = this.props.customers?.find(customer => customer.id === value);
                                this.props.updateSelectedCustomer(
                                    { name: selectedCustomer?.name, id: value?.toString()});
                            }}
                            onChangeFilter={this.onChangeFilter}
                            label={getText("app.select-a-customer")}
                            placeholder="Zoek en maak een keuze"
                            options={this.state.defaultOptions}
                            withFilters
                            validationFunction={(value: string | number) =>
                                hasValueValidation(value.toString(),
                                    getText("app.customer-selected") ?? "")}
                        />}
                </Grid>
            </Div>
        ) : (<></>);
    }

    private onChangeFilter(inputValue?: string, callback?: (updatedOptions: SelectOption[]) => void) {
        this.props.getFilteredCustomers(inputValue, callback);
    }
}

/**
 *
 */
export const SetCustomer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SetCustomerComp);

