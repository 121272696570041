/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { Suspense } from "react";
// import { useQuery, useQueryClient } from "react-query";
import { Route, Switch } from "react-router-dom";
import { Props } from "../App";
import { Loading } from "../components/core/09-views/02-pages/Loading";
import { NoMatchingRoute } from "../components/core/09-views/02-pages/NoMatchingRoute";
import { PageDisplay, Pages, SubPageDisplay, SubPages } from "../store/AppDisplays";
import { MHomeItem } from "../store/Init/Types";
import { getText } from "../utils/translations/I18n";
import { NoRole } from "./components/09-views/02-pages/NoRole";
import { QuickSightDashboard } from "./components/09-views/02-pages/QuickSightDashboard";
import ReportGroup from "./components/09-views/02-pages/ReportGroup";
import Reports from "./components/09-views/02-pages/Reports";

export const Component: React.FC<Props> = (props) => {
    const renderDashboardOrDefault = (page: Pages) => {
        if (page === Pages.HOME) {
            const getHomeMenu: MHomeItem | undefined = props.menu?.find(menu => (menu.key === Pages.HOME)) as MHomeItem | undefined;
            return (
                <Route
                    key={"route-" + PageDisplay[page].path}
                    path={PageDisplay[page].path}
                    render={() => (
                        getHomeMenu ?
                            <QuickSightDashboard title={getHomeMenu.name ?? getText("app.dashboard", "Dashboard")} id={getHomeMenu.dashboardId}/> :
                            <><NoMatchingRoute
                            // eslint-disable-next-line max-len
                                title={"Claude can't find the report"}
                                description={"This is odd, try to refresh the application."}
                            /></>
                    )}
                    exact
                />);
        } else {
            return (
                <Route
                    key={"route-" + PageDisplay[page].path}
                    path={PageDisplay[page].path}
                    component={PageDisplay[page].component}
                    exact
                />);
        }
    };

    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                {
                    (!props.availablePages.isEmpty()) ? (
                        [
                            props.availablePages.map((page: Pages) => ([
                                (page !== Pages.REPORTS ?
                                    renderDashboardOrDefault(page)
                                    :
                                    <Route
                                        key={"route-" + PageDisplay[page].path}
                                        path={PageDisplay[page].path}
                                        render={() => (
                                            <Reports results={props.availableReports} />
                                        )}
                                        exact
                                    />
                                ),
                                PageDisplay[page].subpages.map((subpage: SubPages, subindex) => (
                                    <Route
                                        key={"sub-route-" + SubPageDisplay[subpage].path + subindex}
                                        path={SubPageDisplay[subpage].path}
                                        component={SubPageDisplay[subpage].component}
                                        exact
                                    />
                                )),
                                page === Pages.REPORTS && (
                                    props.availableReports?.map((r, index) => {
                                        const reportDetails = r.items?.map(m => (
                                            <Route
                                                key={"report-route-" + PageDisplay[Pages.REPORTS].path + "/" + r.name.toLowerCase() + "/" + m.id.toLowerCase() + index}
                                                path={PageDisplay[Pages.REPORTS].path + "/" + r.name.toLowerCase() + "/" + m.id.toLowerCase()}
                                                render={() => (
                                                    <QuickSightDashboard goBackLink={PageDisplay[Pages.REPORTS].path + "/" + r.name.toLowerCase()} title={m.name} id={m.id}/>
                                                )}
                                                exact
                                            />
                                        ));
                                        return [
                                            <Route
                                                key={"report-route-" + PageDisplay[Pages.REPORTS].path + "/" + r.name.toLowerCase() + index}
                                                path={PageDisplay[Pages.REPORTS].path + "/" + r.name.toLowerCase()}
                                                render={() => (
                                                    <ReportGroup title={r.name} results={r.items} goBackLink={PageDisplay[Pages.REPORTS].path} currentPath={PageDisplay[Pages.REPORTS].path + "/" + r.name.toLowerCase()} />
                                                )}
                                                exact
                                            />,
                                            ...(reportDetails) ?? [],
                                        ];
                                    })
                                ),
                            ]
                            )),
                            <Route
                                key={"fallback-no-route"}
                                render={() => (
                                    <NoMatchingRoute
                                    // eslint-disable-next-line max-len
                                        title={!props.availablePages ? "Claude is not initiated yet" : undefined}
                                        description={!props.availablePages ?
                                            "It could be that the application is still loading.." : undefined}
                                    />
                                )}
                            />,
                        ]
                    ) : (
                        <Route key={"fallback-no-role"} path="*" exact component={NoRole} />
                    )}
            </Switch>
        </Suspense>
    );
};

