import * as React from "react";
import { ThemeConsumerProps } from "../../../theme/_Types";
import { joinClasses } from "../../../utils/Filters";
import { ensure } from "../../../utils/Optional";
import licensePlateLogo from "../../../assets/images/libs/core/background-licenseplate.svg";
import * as I18n from "../../../utils/translations/I18n";

interface OwnProps {
    value?: string | number;
    onChange?: (value?: string) => void;
    disabled?: boolean;
    required?: boolean;
    asSummary?: boolean;
}

type Props = OwnProps & ThemeConsumerProps;

/**
 *
 * @param props
 */
export const LicensePlateInput: React.FunctionComponent<Props> = (props) => {
    const clazzName = joinClasses(
        props.className,
        "scl-a-input__field",
        "scl-a-license__plate",
    );

    const onChange = !!props.onChange
        ? (evt: React.ChangeEvent<HTMLInputElement>) => {
            evt.preventDefault();
            ensure(props.onChange)(evt.target.value || undefined);
        }
        : undefined;

    return (
        <>
            <img src={licensePlateLogo} alt={"license-plate"} className={"scl-a-license__background"} />
            <input
                type={"text"}
                className={clazzName}
                value={props.value}
                onChange={onChange}
                disabled={!!props.disabled}
                required={props.required}
                placeholder={I18n.getText("mary.05-atoms.licenseplateinput.placeholder",
                    "CL-001-E")}
            />
        </>
    );
};
