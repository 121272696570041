
import { useKeycloak } from "@react-keycloak/web";
import Axios, { AxiosInstance } from "axios";
import * as React from "react";

export const AxiosContext = React.createContext<AxiosInstance>(Axios.create());

export default function AxiosProvider({
    children,
}: React.PropsWithChildren<unknown>) {
    const { keycloak } = useKeycloak();

    const axios = React.useMemo(() => {
        const axiosConfig = Axios.create({
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
            baseURL: process.env.REACT_APP_API_ENDPOINT ?? "",
        });

        axiosConfig.interceptors.request.use((config) => {
            // Read token for anywhere, in this case directly from localStorage
            if (keycloak?.authenticated && (!!localStorage.getItem("token") && localStorage.getItem("token") !== "")) {
                console.log("Claude API call, authenticated with IRIS.");
                if (keycloak.isTokenExpired(50)){
                    console.log("Claude API call, access token is expired.");
                    keycloak.updateToken(50).then(() => {
                        console.log("Claude has successfully updated the access token.");
                    }).catch((e) => {
                        console.log("Claude could not update the access token.", e);
                    });
                }
            } else {
                console.warn("Claude API call, not authenticated with IRIS.");
                keycloak?.logout({redirectUri: process.env.REACT_APP_URL}).then(() => {
                    console.log("Iris: Logout success");
                }).catch(() => {
                    console.log("Iris: Logout failed");
                });
            }

            const token = keycloak?.token;
            if (token) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        }, (e) => {
            console.error("Claude can't connect to API (axios): ", e);
            return Promise.reject(e);
        });

        return axiosConfig;
    }, []);

    return (
        <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
    );
}

export function useAxios() {
    return React.useContext(AxiosContext);
}
