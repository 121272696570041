import * as React from "react";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { InfoCard } from "../../../../../components/core/06-molecules/InfoCard";
import { MReportGroupItem } from "../../../../../store/Init/Types";

/**
 *
 */
interface OwnProps {
    results?: MReportGroupItem[];
    currentPath?: string;
}

type Props = OwnProps;

/**
 *
 */
export class GroupComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Div className="scl-b-row">
                    {
                        this.props.results?.map((report, index) => (
                            <Grid key={index} size={{ xs: 12, md: 6, lg: 3 }}>
                                <InfoCard
                                    title={report.name}
                                    navLink={this.props.currentPath
                                        ? `${this.props.currentPath}/${report.id.toLowerCase()}`
                                        : undefined}
                                    disabled={!this.props.currentPath}
                                />
                            </Grid>
                        ))
                    }
                </Div>
            </React.Fragment>
        );
    }
}
