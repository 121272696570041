/* eslint-disable max-len */
import React from "react";
import { connect } from "react-redux";
import { LoadingIndications, LoadingIndicator } from "../../../components/core/05-atoms/LoadingIndicator";
import { DispatchFunc } from "../../../store/ActionTypes";
import { updateUserContext, updateUserContextIsLoading } from "../../../store/Init/UpdateUserContext";
import { isRemoteLoading, ReduxState, RemoteErrors } from "../../../store/ReduxState";
import { RemoteScope } from "../../../store/RemoteTypes";
import { ThemePalette } from "../../../theme/_Types";
import { Component } from "./CustomerContext/Component";
import { Customer, Customers } from "../../../store/Customers/Types";
import { UserContext } from "../../../store/Users/Types";
import { remoteTrigger } from "../../../store/RemoteActions";
import { GlobalLoadingProps, loadingMessagesGlobal } from "../../../store/AppTypes";


interface OwnProps {
}

export interface StateProps extends GlobalLoadingProps {
    customerId?: string;
    customerName?: string;
    userContext?: UserContext;
    customers?: Customers;
    customerErrors?: RemoteErrors;
    selectedRole?: string;
}

/**
 *
 */
interface DispatchProps {
    updateCurrentCustomer: (customer?: Customer, userContext?: UserContext) => void;
    updateUserContextLoading: (loading: boolean) => void;
    getCustomers: () => void;
}

/**
 *
 */
type Props = React.PropsWithChildren<OwnProps & StateProps & DispatchProps>;

/**
 *
 */
export interface State {
    init: boolean;
}


export const mapStateToProps = (s: ReduxState): StateProps => ({
    isLoading: {
        customers: isRemoteLoading(s, RemoteScope.CUSTOMERS),
        customer: !!s.prop("userContextCustomerIsLoading"),
        role: !!s.prop("userContextRoleIsLoading"),
        init: isRemoteLoading(s, RemoteScope.INIT),
    },
    isLoaded: {
        init: !!s.prop("remote").prop(RemoteScope.INIT),
    },
    customerId: s.prop("selectedCustomer"),
    customerName: s.prop("selectedCustomerName"),
    userContext: s.prop("userContext"),
    customers: s.prop("remote").prop(RemoteScope.CUSTOMERS),
    customerErrors: s.prop("remoteErrors").get(RemoteScope.CUSTOMERS),
    selectedRole: s.prop("selectedRole"),
});


export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    updateUserContextLoading: (loading) =>  dispatch(updateUserContextIsLoading(loading, "userContextCustomerIsLoading")),
    updateCurrentCustomer: (customer, userContext) => {
        const currentContext = userContext;
        const userRoleContext: UserContext = {
            ...(currentContext ? currentContext : {} as UserContext),
            customer: customer,
        };
        dispatch(updateUserContext(userRoleContext));
    },
    getCustomers: () => {
        dispatch(remoteTrigger(RemoteScope.CUSTOMERS, {}));
    },
});

/**
 *
 */
export class CustomerContextWrapperComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            init: true,
        };

        this.checkToClearCustomer = this.checkToClearCustomer.bind(this);
    }

    public componentDidMount() {
        this.setState({
            init: false,
        });
        if (!this.props.customers && !this.props.customerErrors && this.props.selectedRole) {
            this.props.getCustomers();
        }
        this.checkToClearCustomer();
    }

    public componentDidUpdate(_prevProps: Props) {
        this.checkToClearCustomer();
    }


    public render() {
        if (!this.props.isLoading.customers && !this.props.isLoading.init) {
            if (this.props.customers && this.props.customers?.length > 0) {
                return (
                    <Component
                        id={this.props.customerId}
                        init={this.state.init}
                        updateUserContextLoading={this.props.updateUserContextLoading}
                        updateCurrentCustomer={() => this.props.updateCurrentCustomer(
                            {name: this.props.customerName ?? "", id: this.props.customerId ?? ""}, this.props.userContext)}
                    >
                        {this.props.children}
                    </Component>
                );
            } else {
                return (<>
                    {this.props.children}
                </>);
            }
        } else {
            return (
                <LoadingIndicator type={LoadingIndications.DEFAULT}
                    theme={{ palette: ThemePalette.BRAND_PRIMARY }}>
                    {loadingMessagesGlobal({isLoading: this.props.isLoading})}</LoadingIndicator>
            );
        }
    }

    private checkToClearCustomer() {
        if (!this.props.customerId && this.props.userContext?.customer) {
            this.props.updateCurrentCustomer(undefined, this.props.userContext);
        }
    }
}

export const CustomerContextWrapper = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerContextWrapperComp);


