/* eslint-disable max-len */
import * as React from "react";
import { connect } from "react-redux";
import { Container } from "../../../../components/core/03-base/Container";
import { FlexColumn } from "../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../components/core/03-base/Flex/Row";
import { Button } from "../../../../components/core/05-atoms/Button";
import { IconNames } from "../../../../components/core/05-atoms/Icon";
import { Alignment, ButtonsWrapper, Orientation } from "../../../../components/core/06-molecules/ButtonsWrapper";
import { Navbar } from "../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../components/core/07-organisms/Navbar/Icon";
import {
    NavbarStack,
    NavbarStackContent,
    NavbarStackRight,
} from "../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../components/core/07-organisms/Navbar/Title";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { AvailableRoles, ReduxState, isRemoteLoading } from "../../../../store/ReduxState";
import { ThemeBreakpoints, ThemePalette } from "../../../../theme/_Types";
import { modalClose } from "../../../../utils/redux/ActionTypes";
import * as FormFields from "../../../../components/core/07-organisms/Form/index";
import { updateSelectedRole } from "../../../../store/Init/UpdateSelectedRole";
import { InitApp } from "../../../../store/Init/Types";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { getText } from "../../../../utils/translations/I18n";
import { GlobalLoadingProps, isLoadingGlobal } from "../../../../store/AppTypes";
import { clearSelectedCustomer } from "../../../../store/Init/UpdateSelectedCustomer";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../store/RemoteActions";
import { General } from "./Tabs/General";
import { ChangePassword } from "./Tabs/ChangePassword";
import { Tabs } from "../../../../components/core/09-views/00-blocks/Tabs";

/**
 *
 */
interface OwnProps {
    modalID: string;
}

/**
 *
 */
interface StateProps extends GlobalLoadingProps {
    init?: InitApp;
    currentRole?: AvailableRoles | string;
    hasMultipleRoles: () => boolean | undefined;
}

/**
 *
 */
interface DispatchProps {
    closeModal: (id: string) => void;
    updateSelectedRole: (role: AvailableRoles) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    init: s.prop("remote").prop(RemoteScope.INIT),
    currentRole: s.prop("selectedRole"),
    isLoading: {
        customers: isRemoteLoading(s, RemoteScope.CUSTOMERS),
        customer: !!s.prop("userContextCustomerIsLoading"),
        role: !!s.prop("userContextRoleIsLoading"),
        init: isRemoteLoading(s, RemoteScope.INIT),
    },
    isLoaded: {
        init: !!s.prop("remote").prop(RemoteScope.INIT),
    },
    hasMultipleRoles: () => {
        const roles = s.prop("remote")?.prop(RemoteScope.INIT)?.roles;
        return roles && roles?.length > 1;
    },
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
    updateSelectedRole: (role: AvailableRoles) => {
        dispatch(updateSelectedRole(role));
        dispatch(clearSelectedCustomer());
        dispatch(remoteTrigger(RemoteScope.DELETE_CUSTOMERS, {}));
        dispatch(remoteClearError(RemoteScope.CUSTOMERS));
        dispatch(remoteClearResponse(RemoteScope.CUSTOMERS));
        dispatch(remoteClearError(RemoteScope.FILTERED_CUSTOMERS));
        dispatch(remoteClearResponse(RemoteScope.FILTERED_CUSTOMERS));
        // dispatch(updateSelectedCustomer());
    },
});

export const closeModal = (props: Props) => () => props.closeModal(props.modalID);

/**
 *
 */
export class SelectContextModalComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props) {
        super(props);

        this.getOptions = this.getOptions.bind(this);
        this.onChangeRole = this.onChangeRole.bind(this);
        this.roleValidationMessage = this.roleValidationMessage.bind(this);
    }

    /**
     *
     */
    public render() {
        const tabs = [
            {
                key: "general",
                title: "Algemeen",
                description: "Select context",
                component: General,
            },
            {
                key: "change-password",
                title: "Wachtwoord wijzigen",
                description: "Change password",
                component: ChangePassword,
            },
        ];
        return (
            <FlexColumn>
                <FlexRow isFixed>
                    <Navbar
                        theme={{
                            palette: ThemePalette.BRAND_ACCENT,
                        }}
                    >
                        <NavbarStack>
                            <NavbarStackContent>
                                <NavbarTitle>
                                    {getText("app.select-screen", "Fleet Information context")}
                                </NavbarTitle>
                            </NavbarStackContent>
                            {!isLoadingGlobal({isLoading: this.props.isLoading}) &&
                            <NavbarStackRight>
                                <NavbarIcon>
                                    <Button
                                        className={"scl-o-navbar__icon"}
                                        icon={IconNames.TIMES}
                                        link={{
                                            onClick: closeModal(this.props),
                                        }}
                                    />
                                </NavbarIcon>
                            </NavbarStackRight>
                            }
                        </NavbarStack>
                    </Navbar>
                </FlexRow>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "md": { y: 4 },
                        },
                    }}
                >
                    <Tabs
                        tabs={tabs}
                        defaultTab={tabs[0]}
                        params={{
                            hasMultipleRoles: this.props.hasMultipleRoles(),
                            options: this.getOptions(),
                            currentRole: this.props.currentRole,
                            onChangeRole: this.onChangeRole,
                            roleValidationMessage: this.roleValidationMessage,
                            isLoading: this.props.isLoading,
                        }}
                    />
                </Container>
                <FlexRow isFixed>
                    <FlexColumn
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                        }}
                    >
                        <Container
                            theme={{
                                padding: { "": { y: 2 } },
                            }}
                        >
                            <ButtonsWrapper
                                orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                alignment={Alignment.RIGHT}
                            >
                                <Button
                                    theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}
                                    link={isLoadingGlobal({isLoading: this.props.isLoading}) ? undefined : { onClick: closeModal(this.props) }}
                                    disabled={isLoadingGlobal({isLoading: this.props.isLoading})}
                                >
                                    {getText("app.cancel", "Cancel")}
                                </Button>
                                <Button
                                    theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                    link={isLoadingGlobal({isLoading: this.props.isLoading}) ? undefined : { onClick: closeModal(this.props) }}
                                    disabled={isLoadingGlobal({isLoading: this.props.isLoading})}
                                >
                                    {getText("app.continue", "Continue")}
                                </Button>
                            </ButtonsWrapper>
                        </Container>
                    </FlexColumn>
                </FlexRow>
            </FlexColumn >
        );
    }

    private getOptions() {
        const getOptions =
            this.props.init?.roles.map(role => ({
                label: role.name,
                value: role.id,
            }));
        return getOptions;
    }

    private onChangeRole(value?: string | number) {
        return this.props.updateSelectedRole(value?.toString() as AvailableRoles ?? undefined);
    }

    private roleValidationMessage(value: string | number) {
        return FormFields.hasValueValidation(value.toString(),
            getText("app.role-selected", "Role selected") ?? "" );
    }
}

/**
 *
 */
export const SelectContextModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectContextModalComp);
