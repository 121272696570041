import React from "react";
import { Container } from "../../../../components/core/03-base/Container";
import { FlexRow } from "../../../../components/core/03-base/Flex/Row";
import { LostAndFound } from "../../../../components/core/09-views/00-blocks/LostAndFound";
import { getText } from "../../../../utils/translations/I18n";

interface Props {
}

export const NoRole: React.FunctionComponent<Props> = () => (
    <FlexRow isFixed>
        <Container
            style={{display: "flex", alignItems: "center", justifyContent: "center"}}
            className="scl-h-text-align--center"
            theme={{
                padding: { "": { y: 3 }, "sm": { y: 4 } },
            }}
        >
            <LostAndFound
                title={getText("app.no-role-active", "No role active") ?? ""}
                // eslint-disable-next-line max-len
                description={getText("app.no-role-active-description", "You need a role to continue, without a role you can't use this application.") ?? ""} />
        </Container>
    </FlexRow>
);
