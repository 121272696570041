import * as React from "react";
import { ValidationStatus } from "./_Types";
import { FieldValidationProps, FieldValueProps } from "./Field";
import { ThemePaletteState } from "../../../../theme/_Types";
import { isValidLicensePlate } from "../../../../utils/LicensePlate";
import * as I18n from "../../../../utils/translations/I18n";

export interface FieldCheckValidationProps extends FieldValidationProps, FieldValueProps {
}

export interface ValidationFeedback {
    status: ValidationStatus;
    message?: string | React.ReactNode;
}

export const validateValue = (props: FieldCheckValidationProps): ValidationStatus => {
    if ((props.options && props.options.length === 1) || !!props.disabled) {
        return ValidationStatus.DISABLED;
    }

    if (!props.value && props.value !== 0) {
        if (props.values) {
            if (props.validationFunction) {
                return props.validationFunction(props.values.toString()).status;
            }
        } else {
            return ValidationStatus.DEFAULT;
        }
    }

    if ((typeof props.value === "string" || typeof props.value === "number") && props.validationFunction) {
        return props.validationFunction(props.value).status;
    }

    return ValidationStatus.SUCCESS;
};

export const fieldValidationMessage = (props: FieldCheckValidationProps) => {
    const value = validateValue(props);

    if (props.validationFunction && (props.value || props.value === 0)
        && (value === ValidationStatus.ERROR
            || value === ValidationStatus.SUCCESS
            || value === ValidationStatus.DISABLED)
    ) {
        return props.validationFunction(props.value).message;
    }

    if (props.validationFunction && ((props.values))
    && (value === ValidationStatus.ERROR
        || value === ValidationStatus.SUCCESS
        || value === ValidationStatus.DISABLED)
    ) {
        return props.validationFunction(props.values.toString()).message;
    }

    return undefined;
};

export const fieldValidationIcon = (props: FieldCheckValidationProps) => {
    const value = validateValue(props);

    // TODO: Refactor, the Icons should be coming from Mary.
    if (props.validationFunction && (props.value || props.value === 0)
        && (value === ValidationStatus.ERROR)
        && props.validationFunction(props.value).message
    ) {
        return <i className="fa fa-exclamation-triangle" />;
    }

    if (props.validationFunction && (props.value || props.value === 0)
        && (value === ValidationStatus.SUCCESS)
        && props.validationFunction(props.value).message
    ) {
        return <i className="fa fa-check" />;
    }

    if (props.validationFunction && (props.value || props.value === 0)
        && (value === ValidationStatus.DISABLED)
        && props.validationFunction(props.value).message
    ) {
        return <i className="fa fa-lock" />;
    }

    if (props.validationFunction && ((props.values))
    && (value === ValidationStatus.ERROR)
    && props.validationFunction(props.values.toString()).message
    ) {
        return <i className="fa fa-exclamation-triangle" />;
    }

    if (props.validationFunction && ((props.values))
        && (value === ValidationStatus.SUCCESS)
        && props.validationFunction(props.values.toString()).message
    ) {
        return <i className="fa fa-check" />;
    }

    if (props.validationFunction && ((props.values))
        && (value === ValidationStatus.DISABLED)
        && props.validationFunction(props.values.toString()).message
    ) {
        return <i className="fa fa-lock" />;
    }

    return undefined;
};

export const fieldValidationState = (props: FieldCheckValidationProps) => {
    const value = validateValue(props);

    switch (value) {
    case ValidationStatus.DISABLED:
        return ThemePaletteState.DISABLED;
    case ValidationStatus.ERROR:
        return ThemePaletteState.DANGER;
    case ValidationStatus.SUCCESS:
        return ThemePaletteState.SUCCESS;
    case ValidationStatus.WARNING:
        return ThemePaletteState.WARNING;
    default:
        return undefined;
    }
};

export const hasValueValidation = (value: string | number, message: string): ValidationFeedback => {
    if (typeof value !== "number" && !value) {
        return {
            status: ValidationStatus.ERROR,
        };
    }

    return {
        status: ValidationStatus.SUCCESS,
        message: message,
    };
};

export const hasDateValueValidation = (value: string, message: string): ValidationFeedback => {
    if (!/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/i.test(value)) {
        return {
            status: ValidationStatus.ERROR,
            message: I18n.getText("mary.07-organisms.form.field.utils.date--error", "The date format is not correct."),
        };
    }

    return {
        status: ValidationStatus.SUCCESS,
        message: message,
    };
};

export const hasNotExceedValueValidation =
    (value: string | number, message: string, max?: number): ValidationFeedback => {
        if (!!max && typeof value === "number" && value > max) {
            return {
                status: ValidationStatus.ERROR,
                message: I18n.getText("mary.07-organisms.form.field.utils.exceed-value--error",
                    "Amount provided exceeds the limit ({{max}}).", {max: max.toString()}),
            };
        }

        return hasValueValidation(value, message);
    };

export const hasNotExceedLengthValidation =
    (value: string | number, message: string, max?: number): ValidationFeedback => {
        if (!!max && typeof value === "string" && value && value.length > max) {
            return {
                status: ValidationStatus.ERROR,
                message: I18n.getText("mary.07-organisms.form.field.utils.exceed-length--error",
                    "Max. ({{max}}) characters exceeded.", {max: max.toString()}),
            };
        }

        return hasValueValidation(value, message);
    };

export const meetsPasswordValidation =
    (value: string | number, message: string, max?: number, min?: number): ValidationFeedback => {
        if (!!max && !!min && typeof value === "string" && value) {
            if (value.length < min || value.length > max) {
                return {
                    status: ValidationStatus.ERROR,
                    message: I18n.getText("mary.07-organisms.form.field.utils.meet-password-length--error",
                        "Password must be between ({{min}}) and ({{max}}) characters.",
                        {min: min.toString(), max: max.toString()}),
                };
            }
            if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).*$/.test(value)) {
                return {
                    status: ValidationStatus.ERROR,
                    // eslint-disable-next-line max-len
                    message: I18n.getText("mary.07-organisms.form.field.utils.meet-password--error",
                        // eslint-disable-next-line max-len
                        "Password must have atleast 1 number, 1 upper case letter, 1 lower case letter & 1 special character."),
                };
            }
        }

        return hasValueValidation(value, message);
    };

export const hasLicensePlateValidation =
    (value: string | number, message: string): ValidationFeedback => {
        if (typeof value !== "undefined" && !isValidLicensePlate(value.toString())) {
            return {
                status: ValidationStatus.ERROR,
                message:  I18n.getText("mary.07-organisms.form.field.utils.has-license-plate--error",
                    "Please provide a valid license plate."),
            };
        }

        return hasValueValidation(value, message);
    };

export const hasEmailValidation =
    (value: string | number, message: string): ValidationFeedback => {
        const isValidEmail = (val: string) => {
            const regex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "g");
            return regex.test(val);
        };
        if (typeof value !== "undefined" && !isValidEmail(value.toString())) {
            return {
                status: ValidationStatus.ERROR,
                message: I18n.getText("mary.07-organisms.form.field.utils.has-email--error",
                    "Please provide a valid email."),
            };
        }

        return hasValueValidation(value, message);
    };
