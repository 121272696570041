/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
/**
 *
 */
export const refreshMary = () => {
    if (window) {
        if("caches" in window){
            caches.keys()
                .then((names) => {
                    names.forEach(async (name) => {
                        await caches.delete(name);
                    });
                });
        }
        window.location.reload();
    }
};


export const mailToData = (data: string, type?: string) => (
    `mailto:claude@vwpfs.nl?subject=${encodeURI(`[FLINNL-front] ${!!type ? type : "Bug report"}`)}`
        + `&body=${encodeURI("Dear Claude,\n\n\turl: " + document.location.href + "\n\tdata: " + data)}`
);
