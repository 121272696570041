/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { AxiosResponse } from "axios";
import { InitApp } from "./Types";
import { Customers } from "../Customers/Types";

export const mapInitResponse =
 (resp: AxiosResponse): InitApp => resp?.data;

export const mapCustomersResponse =
(resp: AxiosResponse): Customers => resp?.data;
