/* eslint-disable max-len */
import React from "react";
import { useKeycloak } from "@react-keycloak/web";

export const KeycloakLoggedIn: React.FC = (props) => {
    const { keycloak } = useKeycloak();
    return (keycloak?.authenticated ? <>{props.children}</> : null);
};

export const KeycloakNotLoggedIn: React.FC = (props) => {
    const { keycloak } = useKeycloak();
    return (!keycloak?.authenticated ? <>{props.children}</> : null);
};
