import Axios, { AxiosInstance, CancelTokenSource } from "axios";
import { Map, OrderedSet } from "immutable";
import { Action } from "redux";
import { Themes } from "../theme/_Types";
import { Opt, Optional } from "../utils/Optional";
import { MaryState } from "../utils/redux/MaryProvider";
import { State, StateInit } from "../utils/redux/State";
import { ActionTypeKeys, Config, ReducerFunc } from "./ActionTypes";
import { Pages } from "./AppDisplays";
import { UserInfo } from "./AppTypes";
import { FilterScope } from "./FilterTypes";
import { InitApp } from "./Init/Types";
import { RemoteErrorType, RemoteScope } from "./RemoteTypes";
import { RoleRequest } from "./Roles/Types";
import { UserContext, UserFilters, UserRequest } from "./Users/Types";
import { Customers } from "./Customers/Types";
import { KPIs, KPIsPerCustomer } from "./KPIs/Types";

export const isRemoteLoading =
    (state: ReduxState, scope: RemoteScope): boolean =>
        state.mapProp("remotes", r => r.has(scope));

export const isRemoteLoadingAny =
    (state: ReduxState): boolean =>
        state.mapProp("remotes", r => !r.isEmpty());

export interface RemoteErrors {
    message: string;
    type: RemoteErrorType;
}

export type CurrentPage = Readonly<{
    pages: OrderedSet<Pages>;
}>;

export enum AvailableRoles {
    FLEETMANAGER = "wagenparkbeheerder",
    ACCOUNTMANAGER = "ACCOUNTMANAGER",
    SUPERADMIN = "SUPERADMIN",
    NONE = "NONE",
}

export interface UserContextIsLoading {
    userContextRoleIsLoading?: boolean;
    userContextCustomerIsLoading?: boolean;
}

interface TState extends UserContextIsLoading {
    conf: Config;
    client: AxiosInstance;
    remotes: Map<RemoteScope, Opt<CancelTokenSource>>;
    remote: State<RemoteState>;
    remoteErrors: Map<RemoteScope, RemoteErrors>;
    mary?: MaryState;
    filters: State<FilterStates>;
    user: Optional<UserInfo>;
    pages: State<CurrentPage>;
    sidebarCollapsed: boolean;
    userRequest: State<UserRequest>;
    roleRequest: State<RoleRequest>;
    selectedRole?: AvailableRoles | string;
    selectedCustomer?: string;
    selectedCustomerName?: string;
    userContext?: UserContext;
    currentTheme: State<{theme: Themes}>;
}

/**
 *
 */
export interface RemoteState {
    [RemoteScope.INIT]?: InitApp;
    [RemoteScope.CUSTOMERS]?: Customers;
    [RemoteScope.FILTERED_CUSTOMERS]?: Customers;
    [RemoteScope.DELETE_CUSTOMERS]?: {};
    [RemoteScope.KPIS]?: KPIs;
    [RemoteScope.UPDATE_KPIS]?: KPIs;
    [RemoteScope.DELETE_KPI]?: {};
    [RemoteScope.KPIS_PER_CUSTOMER]?: KPIsPerCustomer;
    [RemoteScope.UPDATE_KPIS_PER_CUSTOMER]?: KPIsPerCustomer;
    [RemoteScope.DELETE_KPI_PER_CUSTOMER]?: {};
}

export type ReduxState = State<TState>;

export type ReducerFn<A extends Action<ActionTypeKeys>> =
    ReducerFunc<ReduxState, A>;

export type Filters = Readonly<{
    [FilterScope.USER]: UserFilters;
}>;

export type FilterStates = {
    [K in keyof Filters]: State<Filters[K]>;
};

export const clearState = (s: ReduxState) => s.clear({mary: true, conf: true});

export const ReduxStateInit: (config: Config) => StateInit<TState> =
    config => (): TState => {
        // not sure, but I guess generic stuff.
        const userRequest = localStorage.getItem?.("userRequest");
        const roleRequest = localStorage.getItem?.("roleRequest");
        const isCollapsed = localStorage.getItem?.("isCollapsed");
        const currentTheme = localStorage.getItem?.("currentTheme")
            ?? process.env.REACT_APP_THEME as Themes ?? Themes.VWPFS;
        // this should not exist.
        const selectedCustomer = localStorage.getItem?.("selectedCustomer");
        const selectedCustomerName = localStorage.getItem?.("selectedCustomerName");
        return ({
            conf: config,
            client: Axios.create({
                baseURL: config.apiEndpoint,
            }),
            remotes: Map(),
            remote: State.create<RemoteState>(() => ({})),
            remoteErrors: Map(),
            mary: undefined,
            filters: State.create<FilterStates>(() => ({
                [FilterScope.USER]: State.create<UserFilters>(() => ({})),
            })),
            user: Optional.empty(),
            pages: State.create<CurrentPage>(() => ({
                pages: OrderedSet([]),
            })),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            sidebarCollapsed: JSON.parse(isCollapsed ?? "false") ?? false,
            userRequest: State.create<UserRequest>(() =>  (userRequest as UserRequest ?? {})),
            roleRequest: State.create<RoleRequest>(() => (roleRequest as RoleRequest ?? {})),
            // context based stuff
            // This selected role should not be set on load.
            selectedRole: undefined,
            selectedCustomer: selectedCustomer ?? undefined,
            selectedCustomerName: selectedCustomerName ?? undefined,
            userContext:  undefined,
            userContextRoleIsLoading: false,
            userContextCustomerIsLoading: false,
            currentTheme: State.create<{theme: Themes}>(() => ({theme: currentTheme as Themes})),
        });
    };

export const initTestState: ReduxState = State.create(ReduxStateInit({apiEndpoint: ""}));
