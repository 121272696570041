import * as React from "react";
import { ThemeConsumerProps } from "../../../theme/_Types";
import { joinClasses } from "../../../utils/Filters";
import { asThemeConsumer } from "../../../theme/Theme";

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
}

/**
 *
 * @param props
 */
const LabelComp: React.FunctionComponent<Props> = (props) => {
    const clazzName = joinClasses(
        props.className || "",
        "scl-a-label__field",
    );

    return !props.children
        ? null
        : (
            <label className={clazzName}>
                {props.children}
            </label>
        );
};

/**
 *
 */
export const Label = asThemeConsumer(LabelComp);
