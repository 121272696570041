import React from "react";
import { LinkTarget } from "../../../../../components/core/05-atoms/Link";
import { ButtonsWrapper, Orientation, Alignment } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { ThemeBreakpoints, ThemePalette } from "../../../../../theme/_Types";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { FlexColumn } from "../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../components/core/03-base/Flex/Row";
import { FillUp } from "../../../../../components/core/04-helpers/FillUp";
import { Container } from "../../../../../components/core/03-base/Container";
import { RTE } from "../../../../../components/core/07-organisms/RTE";

export const ChangePassword: React.FunctionComponent = () => (
    <>
        <FlexColumn>
            <FlexRow>
                <FillUp>
                    <RTE>Klik op onderstaande knop om uw wachtwoord te wijzigen.</RTE>
                    <Container
                        theme={{
                            padding: {
                                "": { y: 3 },
                                "md": { y: 4 },
                            },
                        }}
                    >
                        <ButtonsWrapper
                            orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL}}
                            alignment={Alignment.RIGHT}
                        >
                            <Button
                                link={{
                                    // eslint-disable-next-line max-len
                                    href: `${process.env.REACT_APP_IRIS_URL ?? ""}realms/${process.env.REACT_APP_IRIS_REALM ?? ""}/account/password`,
                                    target: LinkTarget.BLANK,
                                }}
                                theme={{ palette: ThemePalette.BRAND_ACCENT }}
                            >
                                Wijzig wachtwoord
                            </Button>
                        </ButtonsWrapper>
                    </Container>
                </FillUp>
            </FlexRow>
        </FlexColumn>
    </>
);

