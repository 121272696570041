/**
 *
 */
export enum ValidationStatus {
    WARNING = 4,
    DISABLED = 3,
    ERROR = 2,
    SUCCESS = 1,
    DEFAULT = 0,
}
