/* eslint-disable */

import { ReduxState } from "./ReduxState";
import { UserFilters } from "./Users/Types";

export type FilterTypes = UserFilters;
/**
 *
 */
export enum FilterScope {
    USER = "USER",
}

export const filterHasValue = (value?: string | number) => !!value || value === 0;

/**
 *
 * @param s
 */
export const getFilters =
    (s: ReduxState, scope: FilterScope): FilterTypes =>
        s.prop("filters").prop(scope).map(f => ({
            ...f,
        }));

export const searchableFilters =
    (filters: FilterTypes, defaultFilters?: FilterTypes): boolean =>
        Object.keys(filters).filter(filter => !defaultFilters
            ? true
            : Object.keys(defaultFilters)
                .every(dF => (dF !== filter || dF === filter)
                    && (defaultFilters[dF] !== filters[filter]))).some(k => filterHasValue(filters[k]));

export const hasSearchableFilters =
    (s: ReduxState, scope: FilterScope, defaultFilters?: FilterTypes): boolean =>
        scope === FilterScope.USER ?
            s.prop("filters").prop(scope)
                .map(f => searchableFilters(f, defaultFilters))
            : false;
