import React, { useEffect, useState } from "react";
import { ThemePalette } from "../../../../theme/_Types";
import { Container } from "../../../../components/core/03-base/Container";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { LoadingIndications, LoadingIndicator } from "../../../../components/core/05-atoms/LoadingIndicator";
import { NoRole } from "../../../components/09-views/02-pages/NoRole";
import { ErrorMessageComp } from "../../../components/09-views/00-blocks/ErrorMessage";
import { getText } from "../../../../utils/translations/I18n";
import { useClearUserRoleContext } from "../../../api/users";

interface Props {
    clearCurrentRole: () => void;
    updateUserContextLoading: (loading: boolean) => void;
}

export const WithoutRole: React.FC<Props> = (props) => {
    const [isLoading, setLoading] = useState(false);

    const response = useClearUserRoleContext({
        clear: props.clearCurrentRole});

    const hasError = !!response?.error;
    const hasData = response && response.data;

    useEffect(() => {
        setLoading(!!response.isLoading || !!response.isFetching);
        props.updateUserContextLoading(!!response.isLoading || !!response.isFetching);
    });


    const getError = () => (<ErrorMessageComp errorMessage={response?.error?.response?.data?.code} />);

    const getLoader = () => (
        <>
            <LoadingIndicator type={LoadingIndications.DEFAULT}
                theme={{ palette: ThemePalette.BRAND_PRIMARY }}>
                {getText("app.retrieving-role..", "Loading role..")}
            </LoadingIndicator>
        </>
    );

    if (isLoading) {
        return getLoader();
    }

    if (hasError || !hasData) {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { t: 3, b: 6 },
                            "sm": { t: 4, b: 6 },
                        },
                    }}
                >
                    {hasError ? getError() : <><NoRole /></>}
                </Container>
            </Page>
        );
    }

    return (<>{props.children}</>);
};
