/*
 * General utils for managing cookies in Typescript.
 */
export const setCookie = (name: string, val: string) => {
    const date = new Date();
    const value = val;

    // Set it expire in 1 year
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/; secure";
};

export const getCookie = (name: string) => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length === 2) {
        const poppedPart = parts.pop();
        if (poppedPart) {
            return poppedPart.split(";").shift();
        }
    }
    return undefined;
};

export const deleteCookie = (name: string) => {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/; secure";
};
