import React from "react";
import { NavLink } from "react-router-dom";

import {
    ThemeConsumerProps,
    ThemePalette, ThemePaletteState, ThemeProps, ThemeShadowSizes } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { joinClasses } from "../../../utils/Filters";
import { Div } from "../03-base/Div";
import { Badge } from "../05-atoms/Badge";
import { Button } from "../05-atoms/Button";
import { IconNames } from "../05-atoms/Icon";
import { LinkProps } from "../05-atoms/Link";
import { ComingSoon } from "../09-views/00-blocks/ComingSoon";

interface OwnProps extends ThemeProps {
    title: string;
    description?: string | JSX.Element;
    portrait?: boolean;
    navLink?: string;
    link?: LinkProps;
    icon?: IconNames | string;
    beta?: boolean;
    disabled?: boolean;
    badgeLabel?: string;
    selected?: boolean;
}

export type Props = ThemeConsumerProps & OwnProps;

const CardContent: React.FunctionComponent<Props> = props =>
    (
        <>
            <div className="m-info-card__title">
                {props.title}
                {!!props.beta &&
                    <span style={{ fontSize: "15px" }}><ComingSoon /></span>}
            </div>
            {   props.description &&
                <div className="m-info-card__description">{props.description}</div>
            }
            {   props.badgeLabel &&
            <div style={{ width: "115px", marginTop: "5px" }}>
                <Badge
                    theme={{
                        palette: ThemePalette.BRAND_SPECIAL_FIRST,
                    }}
                >
                    {props.badgeLabel}
                </Badge>
            </div>
            }
            { (props.navLink || props.link) &&
                <Button
                    className="m-info-card__icon"
                    theme={{
                        palette: !props.selected ? ThemePalette.BRAND_ACCENT : undefined,
                        paletteState: props.selected ? ThemePaletteState.SUCCESS : undefined,
                        shadow: ThemeShadowSizes.TINY,
                    }}
                    icon={props.icon ? props.icon : IconNames.ARROW_RIGHT}
                    style={props.style}
                />
            }
        </>
    );

const InfoCardComp: React.FunctionComponent<Props> = props => {

    const clazzName = joinClasses(
        "m-info-card",
        !!props.disabled ? "m-info-card--disabled" : undefined,
        props.navLink || props.link ? "m-info-card--clickable" : undefined,
        !!props.beta ? "m-info-card--beta" : undefined,
        !!props.selected ? "m-info-card--selected" : undefined,
    );

    return (
        <Div
            className={clazzName}
            theme={{
                palette: props.theme?.palette ?? ThemePalette.BRAND_ACCENT,
                margin: {
                    "": { b: 2, t: 2 },
                    "sm": { b: 2, t: 3 },
                },
                shadow: ThemeShadowSizes.SMALL,
            }}
        >
            { props.navLink ?
                <NavLink
                    className="m-info-card__content"
                    to={props.navLink}
                    exact
                >
                    {CardContent(props)}
                </NavLink> :
                <a
                    className="m-info-card__content"
                    {...props.link}
                >
                    {CardContent(props)}
                </a>
            }
        </Div>
    );
};

export const InfoCard = asThemeConsumer(InfoCardComp);

export const testUtil = {
    CardContent: CardContent,
};
