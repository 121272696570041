/* eslint-disable max-len */
import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn, UserContextIsLoading } from "../ReduxState";
import { UserContext } from "../Users/Types";

/**
 *
 */
type UpdateUserContextAction = Readonly<
Action<ActionTypeKeys.UPDATE_USER_CONTEXT> & {
    userContext: UserContext;
}>;

export const updateUserContext:
(userContext: UserContext) => UpdateUserContextAction =
    (userContext: UserContext) => ({
        type: ActionTypeKeys.UPDATE_USER_CONTEXT,
        userContext: userContext,
    });

export const updateUserContextReducer:
ReducerFn<UpdateUserContextAction> =
    (s, a) => {
        const userRoleContext = a.userContext;
        localStorage.setItem("userContext", JSON.stringify(userRoleContext) ?? "");
        return (
            s.setProp("userContext", userRoleContext ?? undefined)
        );
    };

export type ClearUserContextAction = Readonly<
Action<ActionTypeKeys.CLEAR_USER_CONTEXT>
>;

/**
 *
 *
 */
export const clearUserContext:
() => ClearUserContextAction =
    () => ({
        type: ActionTypeKeys.CLEAR_USER_CONTEXT,
    });

/**
 *
 */
export const clearUserContextReducer:
ReducerFn<ClearUserContextAction> =
    (s, _a) => {
        localStorage.setItem("userContext", "");
        return (
            s.setProp("userContext", undefined)
        );
    };


type UpdateUserContextIsLoadingAction = Readonly<
Action<ActionTypeKeys.SET_USER_CONTEXT_IS_LOADING> & {
    loading?: boolean;
    context?: keyof UserContextIsLoading;
}>;

export const updateUserContextIsLoading:
(loading: boolean, context?: keyof UserContextIsLoading ) => UpdateUserContextIsLoadingAction =
    (loading, context) => ({
        type: ActionTypeKeys.SET_USER_CONTEXT_IS_LOADING,
        loading,
        context,
    });

export const updateUserContextIsLoadingReducer:
ReducerFn<UpdateUserContextIsLoadingAction> =
    (s, a) => {
        const loading = a.loading;
        if (a.context) {
            return (
                s.setProp(a.context, loading)
            );
        }
        return s;
    };
