import * as React from "react";
import CurrencyFormat from "react-currency-format";

export interface CurrencyInputProps {
    amount?: number | string;
    className?: string;
    isInput?: boolean;
    onChange?: (value?: string) => void;
    placeholder?: string;
    disabled?: boolean;
}

interface ValueProps {
    formattedValue: string;
    value: string;
    floatValue: number;
}

type Props = CurrencyInputProps;

const onValueChange = (values: ValueProps, callback?: (value?: string) => void): void => {
    const value = values.value === "-" ? undefined : values.value;
    return !!callback ? callback(value) : undefined;
};
/**
 *
 * @param props
 */
export const CurrencyInput: React.FunctionComponent<Props> = (props) => {
    const onChangeValue = (values: ValueProps) => {
        onValueChange(values, props.onChange);
    };
    return (
        <CurrencyFormat
            value={props.amount}
            thousandSeparator={"."}
            decimalSeparator={","}
            displayType={!!props.isInput ? "input" : "text"}
            className={props.className}
            decimalScale={2}
            fixedDecimalScale
            allowNegative
            onValueChange={onChangeValue}
            placeholder={props.placeholder}
            disabled={props.disabled}
        />
    );
};

export const testUtil = {
    onValueChange: onValueChange,
};
