import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { UserContext, UserRequest, Users } from "../../store/Users/Types";
import { useAxios } from "../utils/AxiosContext";
import { APIErrorResponses } from "./errors";

export const useGetUsers = (email?: string) => {
    const axios = useAxios();
    const users = useQuery<AxiosResponse<Users>, APIErrorResponses>
    (["users/roles", email], async () => await axios.get("/users/roles", {
        params: {
            email: email,
        },
    }), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        retry: false,
        enabled: false,
    });
    return users;
};


export const usePutUpdateUserRole = (userId: string, userRequest: UserRequest) => {
    const axios = useAxios();
    const user =
    useQuery<AxiosResponse<UserRequest>, APIErrorResponses>(["users/roles/put", userId],
        async () => await axios.put("/users/roles", {
            currentUserRoleType: userRequest.currentUserRoleType,
            newRoleId: userRequest.newRoleId,
            email: userRequest.email,
        }), {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            retry: false,
            enabled: false,
        });
    return user;
};

// Used to set the role on the backend side
export const useSetUserRoleContext = (
    callBack: {
        update: (role: UserContext) => void;
        clear: () => void;
    }, roleId: string) => {
    const axios = useAxios();
    const user =
    useQuery<AxiosResponse<UserContext>, APIErrorResponses>(["users/roles/post", roleId],
        async () => await axios.post(`/users/roles/${roleId}`, {
            responseType: "json",
        }).then((m) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (m.data) {
                callBack.update(m.data as UserContext);
                console.error("when triggering update: with valid role",
                    m.data
                );
            } else {
                // clear the current role
                callBack.clear();
                console.error("when triggering update: No valid role!, clearing.", m);
            }
            return m;
        }),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            retry: false,
            enabled: true,
            cacheTime: 0,
            staleTime: Infinity,
        });
    return user;
};


export const useClearUserRoleContext = (
    callBack: {
        clear: () => void;
    }) => {
    const axios = useAxios();
    const user =
    useQuery<AxiosResponse<UserContext>, APIErrorResponses>(["users/roles/post", "clear"],
        async () => await axios.delete("/users/roles", {
            responseType: "json",
        }).then((m) => {
            // clear the current role
            callBack.clear();
            console.error("when triggering delete: No valid role!, clearing.", m);
            return m;
        }),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            retry: false,
            enabled: true,
            cacheTime: 0,
            staleTime: Infinity,
        });
    return user;
};

