import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { ReduxState } from "./ReduxState";

/**
 * Thunk is the ThunkAction type used by Hermann for declaring thunks / action
 * creators that return thunks.
 *
 * In ThunkAction<R, S, E, A>:
 * R => Return value, a plain redux Action type returned by the thunk (if
 * applicable). May be void.
 * S => State type, return type of Dispatch<S> and getState().
 * E => ExtraArgument, unused.
 * A => ?
 */
export type Thunk<R extends Action<ActionTypeKeys> | void> =
    ThunkAction<R, ReduxState, undefined, Action<ActionTypeKeys>>;

/**
 * DispatchFunc gives an alias for the Dispatch function provided by Redux for
 * easier type handling.
 */
// export type DispatchFunc = Dispatch<Action<ActionTypeKeys>>;
export type DispatchFunc = ThunkDispatch<
ReduxState,
undefined,
Action<ActionTypeKeys>
>;

/**
 * StateFunc gives an aliad for the getState function provided by redux-thunk
 * for easier type handling.
 */
export type StateFunc = () => ReduxState;

/**
 * ReduxStateReducer
 */
export type ReducerFunc<S, A extends Action<ActionTypeKeys>> = (state: S, action: A) => S;

/*
 * Config specifies environment-related configuration parameters for the client.
 */
export type Config = Readonly<{
    apiEndpoint: string;
}>;

/**
 *
 */
export enum ActionTypeKeys {
    /**
     * APP_INIT is triggered when the App component is mounted, meaning the user
     * is successfully authenticated and we can start loading the first dataset.
     */
    APP_INIT = "APP_INIT",
    SIGN_OUT = "SIGN_OUT",
    REMOTE_TRIGGER = "REMOTE_TRIGGER",
    REMOTE_CANCEL = "REMOTE_CANCEL",
    REMOTE_RESPONSE = "REMOTE_SUCCESS",
    REMOTE_ERROR = "REMOTE_ERROR",
    REMOTE_CLEAR_ERROR = "REMOTE_CLEAR_ERROR",
    REMOTE_CLEAR_RESPONSE = "REMOTE_CLEAR_RESPONSE",
    UPDATE_PAGES = "UPDATE_PAGES",
    UPDATE_REPORTS = "UPDATE_REPORTS",
    UPDATE_FILTERS = "UPDATE_FILTERS",
    CLEAR_FILTERS = "CLEAR_FILTERS",
    TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR",
    UPDATE_CURRENT_ROLE = "UPDATE_CURRENT_ROLE",
    CLEAR_CURRENT_ROLE = "CLEAR_CURRENT_ROLE",
    UPDATE_USER_CONTEXT = "UPDATE_USER_CONTEXT",
    CLEAR_USER_CONTEXT = "CLEAR_USER_CONTEXT",
    INIT_UPDATE_USER = "INIT_UPDATE_USER",
    UPDATE_USER = "UPDATE_USER",
    CLEAR_USER = "CLEAR_USER",
    INIT_UPDATE_ROLE = "INIT_UPDATE_ROLE",
    UPDATE_ROLE = "UPDATE_ROLE",
    CLEAR_ROLE = "CLEAR_ROLE",
    UPDATE_ROLE_REPORT = "UPDATE_ROLE_REPORT",
    CLEAR_ROLE_REPORT = "CLEAR_ROLE_REPORT",
    UPDATE_THEME = "UPDATE_THEME",
    UPDATE_CURRENT_CUSTOMER = "UPDATE_CURRENT_CUSTOMER",
    CLEAR_CURRENT_CUSTOMER = "CLEAR_CURRENT_CUSTOMER",
    // Purely to show context loading, when either role or customer post is triggered.
    SET_USER_CONTEXT_IS_LOADING = "SET_USER_CONTEXT_IS_LOADING",
}
