/* eslint-disable max-len */
export const enum ErrorResponses {
    ERR_MISSING_USER_EMAIL = "ERR_MISSING_USER_EMAIL",
    ERR_VALIDATION_FAILED = "ERR_VALIDATION_FAILED",
    ERR_ASSIGNING_ROLE = "ERR_ASSIGNING_ROLE",
    ERR_MISSING_ROLE_TYPE = "ERR_MISSING_ROLE_TYPE",
    ERR_USER_ROLE_NOT_FOUND = "ERR_USER_ROLE_NOT_FOUND",
    ERR_MISSING_DASHBOARD_ID = "ERR_MISSING_DASHBOARD_ID",
    ERR_MISSING_ROLE_ID = "ERR_MISSING_ROLE_ID",
    ERR_ROLE_ALREADY_EXISTS = "ERR_ROLE_ALREADY_EXISTS",
    ERR_CREATING_ROLE = "ERR_CREATING_ROLE",
    ERR_ROLE_NOT_FOUND = "ERR_ROLE_NOT_FOUND",
    ERR_DASHBOARD_NOT_FOUND = "ERR_DASHBOARD_NOT_FOUND",
    ERR_CUSTOMER_NOT_FOUND = "ERR_CUSTOMER_NOT_FOUND",
}

// NOT USED ATM
export const ENErrorDisplay: Readonly<Record<ErrorResponses, string>> = {
    [ErrorResponses.ERR_MISSING_USER_EMAIL]: "You need to provide a valid email",
    [ErrorResponses.ERR_VALIDATION_FAILED]: "We couldn't validate this request, we are truly sorry!",
    [ErrorResponses.ERR_ASSIGNING_ROLE]: "We have trouble assigning the role, sorry!",
    [ErrorResponses.ERR_MISSING_ROLE_TYPE]: "We can't select this role since there is no associated role type.",
    [ErrorResponses.ERR_USER_ROLE_NOT_FOUND]: "This role can't be found.",
    [ErrorResponses.ERR_MISSING_DASHBOARD_ID]: "Can't find the report with the supplied ID.",
    [ErrorResponses.ERR_MISSING_ROLE_ID]: "We are missing the role ID.",
    [ErrorResponses.ERR_ROLE_ALREADY_EXISTS]: "This role already exists, please try another one.",
    [ErrorResponses.ERR_CREATING_ROLE]: "We can't create this role, we are very sorry! ",
    [ErrorResponses.ERR_ROLE_NOT_FOUND]: "This role can't be found.",
    [ErrorResponses.ERR_DASHBOARD_NOT_FOUND]: "This report can not be found.",
    [ErrorResponses.ERR_CUSTOMER_NOT_FOUND]: "This customer can not be chosen.",
};

export const ErrorDisplay: Readonly<Record<ErrorResponses, string>> = {
    [ErrorResponses.ERR_MISSING_USER_EMAIL]: "U moet een geldige e-mail opgeven.",
    [ErrorResponses.ERR_VALIDATION_FAILED]: "Dit request kan niet gevalideerd worden, onze excuses!",
    [ErrorResponses.ERR_ASSIGNING_ROLE]: "Er is een probleem met het toekennen van deze rol.",
    [ErrorResponses.ERR_MISSING_ROLE_TYPE]: "Deze rol kan niet geselecteerd worden, want er is geen rol type gekoppeld aan deze rol.",
    [ErrorResponses.ERR_USER_ROLE_NOT_FOUND]: "Deze rol kan niet gevonden worden.",
    [ErrorResponses.ERR_MISSING_DASHBOARD_ID]: "Het rapport kan niet gevonden worden met de opgegeven ID.",
    [ErrorResponses.ERR_MISSING_ROLE_ID]: "Het rol ID is niet opgegeven.",
    [ErrorResponses.ERR_ROLE_ALREADY_EXISTS]: "Deze rol bestaat al, probeer een andere.",
    [ErrorResponses.ERR_CREATING_ROLE]: "Deze rol kan niet aangemaakt worden.",
    [ErrorResponses.ERR_ROLE_NOT_FOUND]: "Deze rol kan niet gevonden worden.",
    [ErrorResponses.ERR_DASHBOARD_NOT_FOUND]: "Dit rapport kan niet gevonden worden.",
    [ErrorResponses.ERR_CUSTOMER_NOT_FOUND]: "Deze klant kan niet gekozen worden.",
};

export interface APIErrorResponses {
    response: {
        data: {
            code: ErrorResponses;
        };
    };
}

