/* eslint-disable */
import * as React from "react";
import ReactSelect, { OptionTypeBase } from "react-select";
import { ThemeConsumerProps } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { joinClasses } from "../../../utils/Filters";
import { ensure } from "../../../utils/Optional";
import { sortBy } from "sort-by-typescript";
import AsyncSelect from "react-select/async";
import { BaseConsumer } from "../03-base/Base";
import debounce from 'lodash.debounce';
import { MaryReactSelectThemes } from "../../../App/components/08-vendors/react-select/Themes";

/**
 *
 */
type Value = string | number;

/**
 *
 */
export type SelectOption = Readonly<{
    label?: string;
    value: Value;
}>;

/**
 *
 */
export interface SelectProps
    extends ThemeConsumerProps {
    placeholder?: string;
    value?: Value;
    values?: string[] | SelectOption[];
    defaultValue?: string;
    disabled?: boolean;
    options: SelectOption[];
    onChange?: (value: Value) => void;
    multiple?: boolean;
    withFilter?: boolean;
    onChangeMultiSelect?: (selected: string[]) => void;
    onChangeFilterSelect?: (selected: string) => void;
    required?: boolean;
    unsorted?: boolean;
    onChangeFilter?: (inputValue?: string, callback?: (updatedOptions: SelectOption[]) => void) => void;
}

/**
 *
 * @param props
 */
const SelectComp: React.FunctionComponent<SelectProps> = (props) => {

    const clazzName = joinClasses(
        props.className,
        "scl-a-select__field",
    );

    const onChange = !!props.onChange
        ?
        (evt: React.ChangeEvent<HTMLSelectElement>) => {
            evt.preventDefault();
            ensure(props.onChange)(evt.target.value);
        }
        : undefined;

    const onChangeMultiSelect = (value?: any) => {
        if (!!props.onChange) {
            const val = value as SelectOption[];
            ensure(props.onChange)(val.map(p => p.value).toString());
        }
    };

    const onChangeFilterSelect = (value?: any) => {
        if (!!props.onChange) {
            const val = value as SelectOption;
            ensure(props.onChange)((val && val.value) ? val.value.toString() : "");
        }
    };

    if ((props.options && props.options.length > 1)) {
        props.unsorted ? props.options : props.options.sort(sortBy("label"));
    }

    if (!!props.placeholder
        && (props.options && props.options.length > 1)
        && !props.options.find((option) => option.label === props.placeholder)
        && !props.withFilter) {
        props.options.unshift({
            label: props.placeholder,
            value: "",
        });
    }

    const filterList = (inputValue: string, options: SelectOption[]) => {
        return options.filter((i) =>
          i.label?.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const loadOptions =
        debounce(
            (inputValue: string,
            callback: (options: SelectOption[]) => void
        ) => {
            console.log("debounced onChangeFilter with inputValue:", inputValue);
            props.onChangeFilter?.(inputValue, (updatedOptions: SelectOption[]) => {
                console.log("debounced onChangeFilter callback executed with inputValue:", inputValue);
                callback(filterList(inputValue, updatedOptions));
            });
        },
        300);

    return (
        <BaseConsumer>{({ getTheme }) => (
        <div className={clazzName}>
            {!!props.multiple ? (
                <ReactSelect
                    className="scl-a-select__field-multi"
                    classNamePrefix="scl-a-select__field-multi-sub"
                    options={props.options.filter(p => p.label !== props.placeholder)}
                    isMulti
                    placeholder={props.placeholder}
                    onChange={onChangeMultiSelect}
                    value={props.values as OptionTypeBase}
                    theme={MaryReactSelectThemes[getTheme()]}
                />
            ) : !!props.withFilter ? (
                <>
                <AsyncSelect
                    className="scl-a-select__field-with-search"
                    classNamePrefix="scl-a-select__field-with-search-sub"
                    onChange={onChangeFilterSelect}
                    // value={props.value as OptionTypeBase}
                    value={props.options.find(option => option.value === props.value) || null}
                    loadOptions={loadOptions}
                    defaultOptions={props.options}
                    placeholder={props.placeholder}
                    isClearable
                    theme={MaryReactSelectThemes[getTheme()]}
                />
                </>
            ) : (
                <select
                    disabled={!!props.disabled || (props.options && props.options.length <= 1)}
                    placeholder={props.placeholder}
                    onChange={onChange}
                    value={props.value || ""}
                    defaultValue={props.defaultValue}
                >
                    {props.options.map((opt, index) => (
                        <option
                            key={`${index}-${opt.value}`}
                            value={opt.value}
                        >
                            {!!opt.label ? opt.label : opt.value}
                        </option>
                    ))}
                </select>
            )}
        </div>
        )}
    </BaseConsumer>
    );
};

/**
 *
 */
export const Select = asThemeConsumer(SelectComp);
