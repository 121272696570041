import { ThemeConfig } from "react-select/src/theme";
import { Themes } from "../../../../theme/_Types";

export const MaryReactSelectThemes: Readonly<Record<Themes, ThemeConfig>> = {
    [Themes.VWPFS]: (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary: "#004666",
            primary75: "#FFFFFF",
            primary50: "#F0F1F3",
            primary25: "#E1E5EA",
            danger: "#fff",
            dangerLight: "#ff4d4f",
        },
    }),
    [Themes.DUTCHLEASE]: (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            text: "#ffffff",
            textLight: "#ffffff",
            primary: "#1c9d7d",
            primary75: "#000",
            primary50: "#999",
            primary25: "#666",
            neutral0: "#000000",
            neutral1: "#000000",
            neutral2: "#000000",
            neutral3: "#000000",
            neutral4: "#000000",
            neutral5: "#000000",
            neutral10: "#999",
            neutral20: "#888",
            neutral30: "#777",
            neutral40: "#666",
            neutral50: "#555",
            neutral60: "#444",
            neutral70: "#333",
            neutral80: "#fff",
            neutral90: "#1a1a1a",
            neutral100: "#000000",
            neutral1a: "#fff",
            neutral2a: "#fff",
            neutral3a: "#fff",
            neutral4a: "#fff",
            neutral5a: "#fff",
            neutral10a: "#fff",
            neutral20a: "#fff",
            neutral30a: "#fff",
            neutral40a: "#fff",
            neutral50a: "#fff",
            neutral60a: "#fff",
            neutral70a: "#fff",
            neutral80a: "#000",
            neutral90a: "#fff",
            danger: "#fff",
            dangerLight: "#ff4d4f",
        },
    }),
    [Themes.AUDI]: (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary: "#000000",
            primary75: "#FFFFFF",
            primary50: "#F0F1F3",
            primary25: "#E1E5EA",
            danger: "#fff",
            dangerLight: "#ff4d4f",
        },
    }),
    [Themes.AUDI]: (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary: "#1D1F20",
            primary75: "#FFFFFF",
            primary50: "#F0F1F3",
            primary25: "#E1E5EA",
            danger: "#fff",
            dangerLight: "#ff4d4f",
        },
    }),
    [Themes.XLLEASE]: (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary: "#ff5600",
            primary75: "#FFFFFF",
            primary50: "#F0F1F3",
            primary25: "#E1E5EA",
            danger: "#fff",
            dangerLight: "#ff4d4f",
        },
    }),
    [Themes.SEAT]: (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary: "#ea5d1a",
            primary75: "#FFFFFF",
            primary50: "#F0F1F3",
            primary25: "#E1E5EA",
            danger: "#fff",
            dangerLight: "#ff4d4f",
        },
    }),
    [Themes.SKODA]: (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary: "#4ba82e",
            primary75: "#FFFFFF",
            primary50: "#F0F1F3",
            primary25: "#E1E5EA",
            danger: "#fff",
            dangerLight: "#ff4d4f",
        },
    }),
    [Themes.MAN]: (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary: "#303c49",
            primary75: "#FFFFFF",
            primary50: "#F0F1F3",
            primary25: "#E1E5EA",
            danger: "#fff",
            dangerLight: "#ff4d4f",
        },
    }),
    [Themes.POLITIE]: (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary: "#004682",
            primary75: "#FFFFFF",
            primary50: "#F0F1F3",
            primary25: "#E1E5EA",
            danger: "#fff",
            dangerLight: "#ff4d4f",
        },
    }),
    [Themes.VW]: (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary: "#00b0f0",
            primary75: "#001e50",
            primary50: "#F0F1F3",
            primary25: "#E1E5EA",
            danger: "#fff",
            dangerLight: "#ff4d4f",
        },
    }),
    [Themes.CLAUDE]: (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary: "#00a0f9",
            primary75: "#FFFFFF",
            primary50: "#F0F1F3",
            primary25: "#E1E5EA",
            danger: "#fff",
            dangerLight: "#ff4d4f",
        },
    }),
};
