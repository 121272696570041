import React from "react";
import { NavLink } from "react-router-dom";
import { Div } from "../../../../components/core/03-base/Div";
import { FlexRow } from "../../../../components/core/03-base/Flex/Row";
import { Button } from "../../../../components/core/05-atoms/Button";
import { ThemePalette, ThemeShadowSizes } from "../../../../theme/_Types";
import { I18nTextProps } from "../../../../utils/translations/I18n";

interface Props {
    title: string | I18nTextProps;
    description?: string | I18nTextProps;
    goBack?: string;
    goBackHistory?: boolean;
    hasSmallTitle?: boolean;
}

export const StepsHeader: React.FunctionComponent<Props> = props => (
    <FlexRow isFixed style={{ zIndex: 1 }}>
        <Div
            theme={{
                padding: {
                    "": { y: 1, x: 3 },
                    "sm": { y: 1, x: 3 },
                },
                palette: ThemePalette.CONTRAST_PRIMARY,
                shadow: ThemeShadowSizes.SMALL,
            }}
            style={{ minHeight: "32px", minWidth: "100%" }}
        >
            <Div style={{ display: "flex", flexDirection: "row" }}>

                {(props.goBack || !!props.goBackHistory) &&
                    <Div
                        style={{ flex: 1, flexGrow: 0 }}
                        theme={{
                            padding: {
                                "": { r: 2 },
                                "sm": { r: 3 },
                            },
                        }}
                    >
                        {!props.goBackHistory && props.goBack ? (
                            <NavLink
                                to={props.goBack}
                                exact
                            >
                                <Button icon={"chevron-left"} theme={{ palette: ThemePalette.BRAND_ACCENT }} />
                            </NavLink>) : (
                            <Button icon={"chevron-left"} theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                link={{onClick: () => window.history.back()}} />
                        )}
                    </Div>
                }
                <Div
                    style={{ flex: 1, alignSelf: "center" }}
                >
                    {(props.goBack || !!props.goBackHistory) ?
                        <h3 className="scl-b-h scl-b-h6">{props.title}</h3>
                        :
                        <h1
                            className={props.hasSmallTitle ? "scl-b-h scl-b-h6": "scl-b-h scl-b-h2"}
                            style={props.hasSmallTitle ? {lineHeight: "54px"} : {}}
                        >
                            {props.title}
                        </h1>
                    }
                    {props.description &&
                        <Div>
                            <p className="scl-mono-font-size">{props.description}</p>
                        </Div>
                    }
                </Div>
            </Div>
        </Div>
    </FlexRow>
);
