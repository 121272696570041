/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { AxiosResponse } from "axios";
import { KPIs, KPIsPerCustomer } from "./Types";

export const mapKPIsResponse =
(resp: AxiosResponse): KPIs => resp?.data;

export const mapKPIsPerCustomerResponse =
(resp: AxiosResponse): KPIsPerCustomer => resp?.data;
