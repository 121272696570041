/* eslint-disable max-len */
import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";

type UpdateSelectedCustomerAction = Readonly<
Action<ActionTypeKeys.UPDATE_CURRENT_CUSTOMER> & {
    selectedCustomer?: { name?: string; id?: string };
}
>;

export const updateSelectedCustomer:
(selectedCustomer?: { name?: string; id?: string }) => UpdateSelectedCustomerAction =
    (selectedCustomer?: { name?: string; id?: string }) => ({
        type: ActionTypeKeys.UPDATE_CURRENT_CUSTOMER,
        selectedCustomer: selectedCustomer,
    });

export const updateSelectedCustomerReducer:
ReducerFn<UpdateSelectedCustomerAction> =
    (s, a) => {
        localStorage.setItem("selectedCustomer", a.selectedCustomer?.id ?? "");
        localStorage.setItem("selectedCustomerName", a.selectedCustomer?.name ?? "");
        return s.updateWith({
            selectedCustomer: a.selectedCustomer?.id ?? "",
            selectedCustomerName: a.selectedCustomer?.name ?? "",
        });
    };

export type ClearSelectedCustomerAction = Readonly<
Action<ActionTypeKeys.CLEAR_CURRENT_CUSTOMER>
>;

/**
 *
 *
 */
export const clearSelectedCustomer:
() => ClearSelectedCustomerAction =
    () => ({
        type: ActionTypeKeys.CLEAR_CURRENT_CUSTOMER,
    });

/**
 *
 */
export const clearSelectedCustomerReducer:
ReducerFn<ClearSelectedCustomerAction> =
    (s, _a) => {
        localStorage.setItem("selectedCustomer", "");
        localStorage.setItem("selectedCustomerName", "");
        return s.updateWith({
            selectedCustomer: undefined,
            selectedCustomerName: undefined,
        });
    };

