import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { EmbeddedReport, Reports } from "../../store/Reports/Types";
import { useAxios } from "../utils/AxiosContext";
import { APIErrorResponses } from "./errors";

export const useGetDashboards = () => {
    const axios = useAxios();
    const data =
        useQuery<AxiosResponse<Reports>, APIErrorResponses>("reports", async () => await axios.get("/dashboards", {
            responseType: "json",
            method: "GET",
        }
        ),{
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            retry: false,
            enabled: true,
        });
    return data;
};


export const useGetDashboard = (dashboardId: string) => {
    const axios = useAxios();
    const data =
        useQuery<AxiosResponse<EmbeddedReport>,  APIErrorResponses>(["reports", dashboardId], async () =>
            await axios.get(`/dashboards/${dashboardId ?? ""}/embedded`, {
                responseType: "json",
                method: "GET",
            }
            ), {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            retry: false,
            enabled: true,
        });
    return data;
};
