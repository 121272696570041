import React from "react";
import { ThemePalette, ThemeShadowSizes } from "../../../../theme/_Types";
import { Container } from "../../03-base/Container";
import { Div } from "../../03-base/Div";
import { Menu, MenuItem, MenuOrientation } from "../../07-organisms/Menu";

export interface TabType {
    key: string;
    title: string;
    description: string;
    component?: React.FunctionComponent | React.ComponentState;
}

interface OwnProps {
    tabs: TabType[];
    /**
     * Make sure the key of the TabType aligns with the item in tabs,
     * else the tab won't be highlighted.
     */
    defaultTab: TabType;
    params?: any;
    child?: boolean;
    user?: {
        name?: string;
        subtitle?: string;
    };
}

interface State {
    showContent: boolean;
    currentTab: TabType;
}

type Props = OwnProps;

/**
 *
 */
export class Tabs
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.state = { showContent: false, currentTab: this.props.defaultTab };
        this.showTabContent = this.showTabContent.bind(this);
    }

    public componentDidMount() {
        this.showTabContent(this.props.defaultTab);
    }

    public render() {
        return (
            <>
                {!this.props.child &&
                    <Div
                        theme={{
                            padding: {
                                "": { b: 3 },
                                "sm": { b: 4 },
                            },
                        }}
                    >
                        <Menu
                            orientation={MenuOrientation.HORIZONTAL}
                            items={this.getItems(this.props.tabs)}
                        />
                    </Div>
                }
                <Div
                    theme={{
                        padding: {
                            "": { b: 3 },
                            "sm": { b: 4 },
                        },
                    }}
                >
                    <>
                        {this.props.child &&
                            <Div theme={{ shadow: ThemeShadowSizes.TINY }}>
                                <Container
                                    theme={{
                                        palette: ThemePalette.CONTRAST_TERTIARY,
                                        padding: {
                                            "": { t: 1 },
                                            "sm": { t: 2 },
                                        },
                                    }}
                                >
                                    <Menu
                                        orientation={MenuOrientation.HORIZONTAL}
                                        items={this.getItems(this.props.tabs)}
                                    />
                                </Container>
                            </Div>
                        }
                        {this.state.showContent && (
                            React.createElement(this.state.currentTab.component,
                                { ...this.props.params },
                            )
                        )}
                    </>
                </Div>
            </>
        );
    }

    private getItems(tabs: TabType[]): MenuItem[] {
        return tabs
            .map((tab): MenuItem => ({
                disabled: !tab.component,
                children: tab.title,
                link: { onClick: () => this.showTabContent(tab) },
                active: this.state.currentTab.key === tab.key,
            }
            ));
    }

    private showTabContent(tab: TabType) {
        this.setState({
            showContent: true,
            currentTab: tab,
        });
    }
}
