import { State } from "../../utils/redux/State";
import { Customer } from "../Customers/Types";
import { hasRequiredFields } from "../RemoteTypes";
import { RoleType } from "../Roles/Types";

export enum Brand {
    MAN = "man",
    VWPFS = "vwpfs",
    DUTCHLEASE = "dutchlease",
}

export enum Label {
    "_700" = "700",
    "MUN" = "MUN",
    "VAL" = "VAL",
    "MAA" = "MAA",
    "HKA" = "HKA",
    "TBL" = "TBL",
    "CUP" = "CUP",
    "_712" = "_712",
    "MAN" = "MAN",
    "AUL" = "AUL",
    "_730" = "730",
    "_725" = "725",
    "DLN" = "DLN",
    "LUN" = "LUN",
    "_790" = "790",
    "POR" = "POR",
    "SEL" = "SEL",
    "SKL" = "SKL",
    "_710" = "710",
    "VWL" = "VWL",
    "CAR" = "CAR",
    "_711" = "711",
    "_740" = "740",
}

export enum LeasingCompany {
    VolkswagenPonFinancialServices = "VolkswagenPonFinancialServices",
    CupraFinancialServices = "CupraFinancialServices",
    MANFinancialServices = "MANFinancialServices",
    AudiFinancialServices = "AudiFinancialServices",
    DutchLease = "DutchLease",
    XLLease = "XLLease",
    PorscheFinancialServices = "PorscheFinancialServices",
    SEATFinancialServices = "SEATFinancialServices",
    SkodaFinancialServices = "SkodaFinancialServices",
    VolkswagenFinancialServices = "VolkswagenFinancialServices",
}

export interface User {
    id: string;
    email: string;
    brand: Brand | string;
    label: Label | string;
    company: LeasingCompany | string;
    name: string;
    role: string;
    dateOfUpdate: string;
    roleType: RoleType;
}

export interface UserContext {
    id: string;
    brand: Brand | string;
    company: LeasingCompany | string;
    label: Label | string;
    role: string;
    dateOfUpdate: string;
    roleType: RoleType;
    customer?: Customer;
}

export type Users = User[];

export interface UserDetails {
    content: User;
}

export interface UserRequestWithEmail extends UserRequest {
    email: string;
}

export interface UserRequest {
    currentUserRoleType?: RoleType;
    currentUserRoleTypeName?: string;
    newRoleId?: string;
    email?: string;
}

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type UserFilters = Readonly<Partial<{
    email: string;
}>>;

export const canSubmitUserForm =
    (userRequest: State<UserRequest>): boolean => {
        const validMandatoryUserFields =
            userRequest.map(f => hasRequiredFields(
                f,
                ["newRoleId", "currentUserRoleType", "email"],
            ));
        return (
            validMandatoryUserFields
        );
    };
