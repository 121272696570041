import React from "react";
import { FlexColumn } from "../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../components/core/03-base/Flex/Row";
import { FillUp } from "../../../../../components/core/04-helpers/FillUp";
import { Div } from "../../../../../components/core/03-base/Div";
import * as FormFields from "../../../../../components/core/07-organisms/Form/index";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { getText } from "../../../../../utils/translations/I18n";
import { SetCustomer } from "../SelectContextModal/SetCustomer";
import { LoadingIndications, LoadingIndicator } from "../../../../../components/core/05-atoms/LoadingIndicator";
import { isLoadingGlobal, loadingMessagesGlobal } from "../../../../../store/AppTypes";
import { ThemePalette } from "../../../../../theme/_Types";

interface Props {
    hasMultipleRoles: boolean | undefined;
    options: Array<{ label: string; value: string | number }> | undefined;
    currentRole?: string;
    onChangeRole: (value?: string | number) => void;
    roleValidationMessage: (value: string | number) => FormFields.ValidationFeedback;
    isLoading: {
        customers: boolean;
        init: boolean;
        customer: boolean;
        role: boolean;
    };
}

export const General: React.FunctionComponent<Props> = ({
    hasMultipleRoles,
    options,
    currentRole,
    onChangeRole,
    roleValidationMessage,
    isLoading,
}) => (
    <FlexRow
        theme={{
            palette: ThemePalette.CONTRAST_PRIMARY,
        }}
        style={{position: "relative"}}
    >
        {isLoadingGlobal({isLoading: isLoading}) &&
        <Div
            theme={{
                palette: ThemePalette.CONTRAST_PRIMARY,
            }}
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 3,
                flexDirection: "column",
                display: "flex",
            }}><LoadingIndicator type={LoadingIndications.DEFAULT}
            >{loadingMessagesGlobal({isLoading: isLoading})}</LoadingIndicator></Div>
        }
        <FlexColumn>
            <FlexRow>
                <FillUp>
                    {hasMultipleRoles && (
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 12 }}
                            >
                                <FormFields.FormField
                                    label={getText("app.select-a-role", "Select a role")}
                                    placeholder={getText("app.make-a-choice", "Make a choice")}
                                    options={options}
                                    onChange={onChangeRole}
                                    value={currentRole}
                                    validationFunction={roleValidationMessage}
                                />
                            </Grid>
                        </Div>
                    )}
                    <SetCustomer />
                </FillUp>
            </FlexRow>
        </FlexColumn>
    </FlexRow>
);

