/* eslint-disable max-len */
import * as React from "react";
import { connect } from "react-redux";
import * as Cookies from "../../../../utils/Cookies";

import { Container } from "../../../../components/core/03-base/Container";
import { FlexColumn } from "../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../components/core/03-base/Flex/Row";
import { FillUp } from "../../../../components/core/04-helpers/FillUp";
import { Button } from "../../../../components/core/05-atoms/Button";
import { Alignment, ButtonsWrapper, Orientation } from "../../../../components/core/06-molecules/ButtonsWrapper";
import { Navbar } from "../../../../components/core/07-organisms/Navbar";
import {
    NavbarStack,
    NavbarStackContent,
    NavbarStackRight,
} from "../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../components/core/07-organisms/Navbar/Title";
import { RTE } from "../../../../components/core/07-organisms/RTE";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { ThemeBreakpoints, ThemePalette, ThemeSize } from "../../../../theme/_Types";
import { modalClose, modalShow } from "../../../../utils/redux/ActionTypes";
import { LogoutBtn } from "../../05-atoms/keycloaklogoutbtn";
import { Modal } from "../../../../components/core/06-molecules/Modal";
import * as I18n from "../../../../utils/translations/I18n";
import { NavbarIcon } from "../../../../components/core/07-organisms/Navbar/Icon";
import { IconNames } from "../../../../components/core/05-atoms/Icon";

/**
 *
 */
interface DispatchProps {
    closeModal: (id: string) => void;
}

/**
 *
 */
interface OwnProps {
    title: string;
    content: string;
    userId: string;
    force?: boolean;
}

/**
 *
 */
type Props = OwnProps & DispatchProps;

interface State {}

// eslint-disable-next-line no-bitwise
const hashCode = (s: string) => s.split("").reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0);
/**
 *
 */
const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
});

export class DisclaimerComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.acceptDisclaimer = this.acceptDisclaimer.bind(this);
        this.declineDisclaimer = this.declineDisclaimer.bind(this);
    }

    public render() {
        return (
            <>
                <FlexRow isFixed>
                    <Navbar
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                        }}
                    >
                        <NavbarStack>
                            <NavbarStackContent>
                                <NavbarTitle>
                                    {this.props.title}
                                </NavbarTitle>
                            </NavbarStackContent>
                            {this.props.force && (
                                <NavbarStackRight>
                                    <NavbarIcon>
                                        <Button
                                            className={"scl-o-navbar__icon"}
                                            icon={IconNames.TIMES}
                                            link={{
                                                onClick: () => this.props.closeModal("disclaimermodal"),
                                            }}
                                        />
                                    </NavbarIcon>
                                </NavbarStackRight>
                            )
                            }
                        </NavbarStack>
                    </Navbar>
                </FlexRow>
                <FlexRow
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                    }}
                    isScrollable
                >
                    <FillUp>
                        <Container
                            theme={{
                                padding: {
                                    "": { y: 3 },
                                    "md": { y: 4 },
                                },
                            }}
                        >
                            <RTE content={this.props.content} />
                        </Container>
                    </FillUp>
                </FlexRow>

                <FlexRow isFixed>
                    <FlexColumn
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                        }}
                    >
                        <Container
                            theme={{
                                padding: { "": { y: 3 } },
                            }}
                        >
                            <ButtonsWrapper
                                orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL}}
                                alignment={Alignment.RIGHT}>
                                {this.props.force && (
                                    <Button
                                        theme={{palette: ThemePalette.CONTRAST_TERTIARY}}
                                        link={{
                                            onClick: () => this.props.closeModal("disclaimermodal"),
                                        }}
                                    >
                                        {I18n.getText("app.close", "Close")}
                                    </Button>
                                )
                                }
                                <LogoutBtn noIcon label={I18n.getText("app.decline", "Decline")} onSuccess={this.declineDisclaimer}/>
                                <React.Fragment>
                                    <Button
                                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                        link={{ onClick: () => {
                                            this.acceptDisclaimer();
                                            this.props.closeModal("disclaimermodal");
                                        }}}
                                    >
                                        {I18n.getText("app.agree", "Agree")}
                                    </Button>
                                </React.Fragment>
                            </ButtonsWrapper>
                        </Container>
                    </FlexColumn>
                </FlexRow>
            </>
        );
    }

    private acceptDisclaimer = () => {
        Cookies.setCookie(`disclaimer-accepted-by-${hashCode(this.props.userId)}`, "yes");
    };
    private declineDisclaimer = () => {
        Cookies.setCookie(`disclaimer-accepted-by-${hashCode(this.props.userId)}`, "no");
    };
}

export const showDisclaimer = (d: DispatchFunc, userId: string, force?: boolean) => {
    if (Cookies.getCookie(`disclaimer-accepted-by-${hashCode(userId)}`) !== "yes" || force) {
        d(
            modalShow(
                "disclaimermodal",
                () => (
                    <Modal
                        modalID={"disclaimermodal"}
                        theme={{ size: ThemeSize.BIG }}
                    >
                        <DisclaimerModal
                            force={force}
                            userId={userId}
                            title={I18n.getText("app.disclaimer-title", "Legal notice") ?? ""}
                            content={I18n.getText("app.disclaimer-description", `Welcome in the fleet information environment from,

#### Volkswagen Pon Financial Services
\n&nbsp;
\n
**Information on the completeness and liability exclusion for data and figures**

The data and figures we, Volkswagen Pon Financial Services and/or daughter companies, Saturnus 1, 3824 ME Amersfoort, compile and transmit represent an additional service to you. The data and figures compiled and offered to you, have been prepared with the greatest possible care and best conscience, however make no claim to completeness in respect of legal and/or fiscal requirements. Liability on the part of Volkswagen Pon Financial Services and/or daughter companies for the completeness of the data and figures is therefore excluded.

Should you have any questions in the scope of our reporting in order to comply to the legal and/or fiscal requirements, please contact your Account Manager.`) ?? ""}/>
                    </Modal>
                ),
            ),
        );
    }
};

/**
 *
 */
export const DisclaimerModal = connect(
    undefined,
    mapDispatchToProps,
)(DisclaimerComp);
