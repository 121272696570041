import React, { useEffect, useState } from "react";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { StepsHeader } from "../../../../components/core/09-views/00-blocks/StepsHeader";
import { useGetDashboard } from "../../../api/dashboard";
import { ThemePalette } from "../../../../theme/_Types";
import { LoadingIndications, LoadingIndicator } from "../../../../components/core/05-atoms/LoadingIndicator";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import { ErrorMessageComp } from "../00-blocks/ErrorMessage";
import { ErrorResponses } from "../../../api/errors";
import { getText } from "../../../../utils/translations/I18n";
import { useQueryClient } from "react-query";

interface Props {
    id: string;
    title: string;
    goBackLink?: string;
}

interface State {
    ref: React.RefObject<HTMLDivElement>;
    init: boolean;
}

export class QuickSightDashboard
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            ref: React.createRef<HTMLDivElement>(),
            init: true,
        };
    }

    public componentDidMount() {
        this.setState({
            init: false,
        });
    }

    /**
     *
     */
    public render() {
        const ref = this.state.ref;
        return (
            <>
                <StepsHeader
                    title={this.props.title ?? ""}
                    goBackHistory={!!this.props.goBackLink}
                    hasSmallTitle
                />
                <div ref={ref} style={{display: "flex", height: "100%"}}>
                    <QuickSightDashboardComp {...this.props}
                        init={this.state.init}
                        aref={ref}/>
                </div>
            </>
        );
    }
}


export const QuickSightDashboardComp: React.FC<Props
& {aref: React.RefObject<HTMLElement>; init: boolean}> = (props) => {
    const [errorMessage, setErrorMessage] = useState("" as ErrorResponses);
    let isLoading = false;
    let hasError = false;

    const queryClient = useQueryClient();
    if (props.init) {
        queryClient.removeQueries(["reports", props.id]);
    }

    const dashboard = useGetDashboard(props.id);

    const getEmbeddedUrl = dashboard.data?.data.url;
    const getParameters = dashboard.data?.data.parameters;

    isLoading = dashboard.isLoading;
    hasError = !!dashboard.error || !getEmbeddedUrl;

    useEffect(() => {
        if (dashboard?.error?.response?.data?.code) {
            setErrorMessage(dashboard?.error?.response?.data?.code);
        } else {
            setErrorMessage("" as ErrorResponses );
        }
        if (!hasError && !isLoading) {
            showContent();
        }
    });


    const showContent = () => {
        if (getEmbeddedUrl && props.aref.current && props.id) {
            try {
                if (props.aref.current.getAttribute("quicksightRef") !== props.id
                    && props.aref.current.children.length <= 0) {
                    props.aref.current.setAttribute("quicksightRef", props.id);
                    const some = embedDashboard({
                        url: getEmbeddedUrl,
                        container: props.aref.current ?? "",
                        // errorCallback?: Function;
                        // loadCallback?: Function;
                        // parametersChangeCallback?: Function;
                        // selectedSheetChangeCallback?: Function;
                        parameters: getParameters,
                        printEnabled: false,
                        // sheetTabsDisabled?: boolean;
                        // defaultEmbeddingVisualType?: string;
                        width: "100%",
                        height: "100%",
                        // loadingHeight?: string;
                        // scrolling?: string;
                        // className?: string;
                        locale: "nl-NL",
                        footerPaddingEnabled: false,
                        // sheetId?: string;
                        // dashboardId?: string;
                        // iframeResizeOnSheetChange?: boolean;
                        undoRedoDisabled: true,
                        resetDisabled: false,
                        // isQEmbedded?: boolean;
                        // qSearchBarOptions?: QSearchBarOptions;
                    });
                    some.on("load", (e: any) => {
                        console.log("Claude loaded (AWS SDK):", e);
                        isLoading = true;
                    });
                    some.on("error", (e: any) => {
                        console.error("Claude error (AWS SDK):", e);
                        hasError = true;
                    });
                }
            } catch (e) {
                console.warn("Claude catch block error (AWS SDK):", e);
                hasError = true;
            }
        }
    };

    return (<>
        {
            isLoading ? (<Page>
                <LoadingIndicator type={LoadingIndications.DEFAULT}
                    theme={{ palette: ThemePalette.BRAND_PRIMARY }}>
                    {getText("app.Loading..", "Loading..")}</LoadingIndicator></Page>
            ) : (hasError) ? (
                <Page><ErrorMessageComp errorMessage={errorMessage}/></Page>) : null
        }
    </>
    );
};
